import React, { useState, useEffect, useCallback } from 'react'
import Select from 'react-select';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import '../Viewleave.css';
import ReactPaginate from "react-paginate";
import formatDate from '../../Component/DateConvert';
import leaveStatusText from '../LeaveStatusText';
import { getStaffDetails, getUserDetails } from "../../../Services/Storage";
import { DeleteLeavestaffApi, ViewStaffLeaveApi } from '../../../Config/api';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import getMonthYearData from '../../Component/MonthYearList';
import pageloader from '../../../Loader/pageloading/Pageloader';
// import Refreshimg from '../../../Images/Refreshimg/Refreshimg';
// import { useSelector } from 'react-redux';

//import Confirmation from '../../Component/Confirmation';
import AddStaffLeave from '../Addleave/AddStaffLeave';
import { CiTrash } from 'react-icons/ci';
import DeleteAlert from '../../popup/DeleteAlert';
const StaffLeave = () => {
  const [isaddleaveopen, setaddleaveopen] = useState(false);
  const [currentpage, setCurrentPage] = useState(1);
  // const [search, setSearchword] = useState("");
  const [totalLeaveCount, setTotalLeaveCount] = useState(0);
  const [leaves, setLeaves] = useState([]);
  // const [isAllChecked, setIsAllChecked] = useState(false);
  // const [selectedLeave, setSelectedLeave] = useState([]);
  // const [reloadKey, setReloadKey] = useState(0); // Add this state variable
  const [selectedYear, setSelectedYear] = useState(); // Default to current year
  const [selectedMonth, setSelectedMonth] = useState(); // Default to current month, formatted as two digits
  const [isLoading, setIsLoading] = useState(true); // State for loading
  // const [loading, setloading] = useState(null);
  // const [showConfirmModal, setShowConfirmModal] = useState(false);
  // const [statusToUpdate, setStatusToUpdate] = useState(null);
  // const leaveDetailsRedux = useSelector((state) => state.leaves);
  // useEffect(() => {
  //   setLeaves(leaveDetailsRedux);
  // }, [leaveDetailsRedux])

  const user_details = getUserDetails();
  const staff_details = getStaffDetails();
  const [deleteComponentId, setDeleteComponentId] = useState(null);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);


  const per_page = 10;

  const handleChangeYear = (selectedOption) => {
    setSelectedYear(selectedOption.value);
    setCurrentPage(1)

  };
  const handleChangeMonth = (selectedOption) => {
    setSelectedMonth(selectedOption.value);
    setCurrentPage(1)

  };
  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  // const handleCloseModal = () => {
  //   setShowConfirmModal(false);
  // };
  // const handleupdatestatus = async (status) => {
  //   setStatusToUpdate(status);
  //   setShowConfirmModal(true);
  // };
  // const handleConfirm = async () => {
  //   setShowConfirmModal(false);
  //   //console.log('Confirmed status change:', selectedLeave);
  //   const payload = {
  //     //status: statusToUpdate,
  //     ids: selectedLeave,
  //   }

  //   try {
  //     // setloading(statusToUpdate);
  //     const response = await UpdateStatusLeave(JSON.stringify(payload));

  //     if (response) {
  //       Store.dispatch(updateLeave(response.data.updated_records));
  //       // response.data.updated_records.forEach(leave => {
  //       //   Store.dispatch(updateLeave(leave));
  //       // });
  //       setSelectedLeave([]);
  //       toast.success('Leave Status Updated successfully!', {
  //         position: "top-center",
  //         autoClose: 2000,
  //         closeButton: false,
  //       });
  //     }
  //     console.log("done")
  //     // setloading(null);

  //   } catch {
  //     console.log("errr")
  //     toast.error('Failed. Please try again.', {
  //       position: "top-center",  // Use a string directly for the position
  //       autoClose: 2000,
  //       closeButton: false,
  //     });
  //   }
  //   // setloading(null);
  // }
  const showConfirmationModalDelete = (delid) => {
    setDeleteComponentId(delid);
    setConfirmationModalOpen(true);

  }
  const closeConfirmationModal = () => {
    setDeleteComponentId(null);
    setConfirmationModalOpen(false);
  };
  const confirmDelete = () => {
    if (deleteComponentId) {
      deletesalaryComponent(deleteComponentId);
      closeConfirmationModal();
    }
  };
  const deletesalaryComponent = async (componentId) => {
    try {
      const response = await DeleteLeavestaffApi(componentId);
      if (response) {
        const updatedList = leaves.filter((component) => component.id !== componentId);

        setLeaves(updatedList);
      }
    }
    catch (error) {

    }
  }
  const fetchleaveList = useCallback(async () => {
    try {

      setIsLoading(true); // Show the loader
      const response = await ViewStaffLeaveApi(
        `?user_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&year=${selectedYear}&month=${selectedMonth}&admin_id=${staff_details.register_details.user_id}`
      );
      setLeaves(response.data.emp_leave_view);
      // Store.dispatch(setLeave(response.data.emp_leave_view));
      setTotalLeaveCount(response.data.page_context.total);
    } catch (error) {
    } finally {
      setIsLoading(false); // Hide the loader
    }
  }, [currentpage, selectedMonth, selectedYear, user_details.id, staff_details.register_details.user_id]);
  useEffect(() => {
    fetchleaveList();
  },
    [fetchleaveList]);

  const handleDataFromChild = (data) => {
    if (data) {
      fetchleaveList();
    }
    // setLeaves((prev) => [
    //     data,
    //     ...prev,
    // ])
  }
  // const fetchLeaveListDefault = async () => {
  //   setCurrentPage(1);
  //   setSelectedYear('');
  //   setSearchword('');
  //   // setReloadKey(prevKey => prevKey + 1);
  // };

  const addStaffleave = () => {
    setaddleaveopen(true);
  }
  const closeaddleave = () => {
    setaddleaveopen(false);
    // setReloadKey(prevKey => prevKey + 1); // Trigger a reload
  }
  // useEffect(() => {
  //   if (selectedLeave.length === leaves.length) {
  //     // setIsAllChecked(true);
  //   } else {
  //     // setIsAllChecked(false);
  //   }
  // }, [selectedLeave, leaves.length]);

  // const handleSearch = (event) => {
  //   setSearchword(event.target.value);
  // }

  // useEffect(() => {
  //   if (selectedLeave.length === leaves.length) {
  //     // setIsAllChecked(true);
  //   } else {
  //     // setIsAllChecked(false);
  //   }
  // }, [selectedLeave, leaves.length]);

  // const handleAllCheckboxChange = () => {
  //   if (isAllChecked) {
  //     setSelectedLeave([]);
  //   } else {
  //     setSelectedLeave(leaves.map(leave => leave.id));
  //   }
  //   setIsAllChecked(!isAllChecked);
  // };
  // const handleCheckboxChange = (id) => {
  //   setSelectedLeave(prevState =>
  //     prevState.includes(id) ? prevState.filter(leaveId => leaveId !== id) : [...prevState, id]
  //   );
  // };
  const { months, years } = getMonthYearData();
  //const keys = Object.keys(months);
  const monthData = Object.keys(months).sort((a, b) => a - b).map(key => (
    {
      value: key,
      label: months[key],
    }));

  const yearData = Object.keys(years).map(key => ({
    value: years[key],
    label: years[key],
  }));
  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>
                <div className='pages-subheader-sub-1'>
                  <p>Leave</p>
                  <span className='header-search-add-parent'>
                    {/* <div className='pages-serach-view'>
                        <CiSearch className='page-search-icon' />
                        <input className='pages-serach' onChange={handleSearch} placeholder='Search Leave ' type='search' />
                      </div> */}
                    <button className='pages-addempbutton' onClick={addStaffleave}>Add Leave</button>
                  </span>
                </div>

                <div className='pages-line'></div>
              </div>
              <div className='pages-filter-options'>
                <div className='pages-filter-sub'>
                  <p>Filter:</p>

                  <Select className='pages-dropdown' options={yearData} value={yearData.find(option => option.value === selectedYear)} onChange={handleChangeYear} placeholder="Select Year" />
                  <Select className='pages-dropdown' options={monthData} onChange={handleChangeMonth} value={monthData.find(option => option.value === selectedMonth)} placeholder="Select Month" />

                  {/* <button className="default-button" onClick={fetchLeaveListDefault}> Default </button> */}
                  {/* <img src={Refreshimg.refreshimg} onClick={fetchLeaveListDefault} alt='img'/> */}
                </div>
              </div>
              <div className='pages-maincontent'>
                {isLoading ? ( // Show loader while data is loading
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                ) : (
                  <table className='pages-table'>
                    <thead>
                      <tr>
                        <th></th>
                        <th> S.No</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>No OF Days</th>
                        <th>Leave Type</th>
                        <th>Leave Reason</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {leaves.length > 0 ? (
                        leaves.map((leave, index) => (
                          <tr key={leave.id}>
                            <td></td>
                            <td>{index + 1}</td>
                            <td>{formatDate(leave.from_date)}</td>
                            <td>{formatDate(leave.to_date)}</td>
                            <td>{leave.total_days}</td>
                            <td>{leave.leave_type}</td>
                            <td>{leave.reason}</td>
                            <td style={{ color: leaveStatusText(leave.status).color }}>
                              {leaveStatusText(leave.status).text}
                            </td>
                            <td>{leave.status === "0" ? (
                              <CiTrash onClick={() => showConfirmationModalDelete(leave.id)} style={{ color: "red", cursor: "pointer", fontSize: "14px" }} />
                            ) : (
                              <></>

                            )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={8} className="no-data-table">

                            <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />



                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
                <div style={{ float: "right" }} id="pagination_link"></div>
                <div >
                  {(!isLoading)&&(totalLeaveCount > per_page )? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totalLeaveCount / per_page)}
                        pageRangeDisplayed={3}
                        forcePage={currentpage - 1} // Adjust index (0-based)

                        marginPagesDisplayed={2}
                        onPageChange={currentPageNo}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Confirmation
        isOpen={showConfirmModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        message={`Are you sure you want to ${leaveStatusText(String(statusToUpdate))} the selected leave?`}
      /> */}
      <AddStaffLeave
        show={isaddleaveopen}
        onclose={closeaddleave}
        sendDataToParent={handleDataFromChild}

      />
      <DeleteAlert
        isOpen={isConfirmationModalOpen}
        message="Really, would you like to remove this Leave?"
        onCancel={closeConfirmationModal}
        onConfirm={confirmDelete}
      />
    </div>
  );
}
export default StaffLeave