import React, { useRef, useState,useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import pageloader from '../../../Loader/pageloading/Pageloader';

const EmployeeDetails = ({ employeeData, show, close }) => {

  const printRef = useRef();
  const [loading, setLoading] = useState(true); // State to manage loading

  const [showButtons, setShowButtons] = useState(true); // State to manage button visibility
  useEffect(() => {
    if (show) {
      setLoading(true);
      // Simulate a delay for loader
      setTimeout(() => {
        setLoading(false);
      }, 1000); // Adjust delay as needed
    }
  }, [show]);
  
  if (!show) return null;

  const handlePrint = () => {
    setShowButtons(false); // Hide buttons when printing

    setTimeout(() => {
      const printContent = printRef.current;

      // Create a new window for printing
      const printWindow = window.open('', '_blank');
      printWindow.document.write(`
        <html>
        <head>
          <style>
            body {
              margin: 0;
              padding: 0;
            }
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              padding: 10px;
              text-align: left;
              word-wrap: break-word;
            }
            th {
              background-color: #f2f2f2;
            }
            @media print {
              body * {
                visibility: hidden;
              }
              .print-container, .print-container * {
                visibility: visible;
              }
              .print-container {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                overflow: visible;
              }
              table {
                width: 100%;
              }
            }
          </style>
        </head>
        <body>
          <div class="print-container" style="padding: 20px;">${printContent.innerHTML}</div>
        </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
      setShowButtons(true); // Show buttons again after printing

      printWindow.close();
    }, 0); // Delay the printing until after the state updates
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text('Employee Details', 14, 15);

    const tableData = employeeData.map((emp, index) => ([
      index + 1,

      emp.name,
      emp.email,
      emp.phone,
      `${emp.address_line1}, ${emp.address_line2}`,
      emp.monthly_salary,
      emp.company_name,
      emp.department_name,
      emp.designation_name
    ]));

    doc.autoTable({
      head: [[
        '#', 'Name', 'Email', 'Phone', 'Address',
        'Monthly Salary',
        'Company Name', 'Department'
      ]],
      body: tableData,
      startY: 20
    });

    doc.save('employee_details.pdf');
  };

  return (
    <div style={overlayStyle}>
      {loading ? (
         <div className="loader-container">
         <img src={pageloader.page_loader} alt="Loading..." />
       </div>
     ):(

     
      <div style={popupStyle} ref={printRef} className="print-container">
        {showButtons && (
          <div className='modal-content-close ' style={closeclour} onClick={close}>
            X
          </div>
        )}
        <div>
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Employee Details</h2>

          {showButtons && (
            <div style={{ display: 'flex', justifyContent: 'center', gap: '15px', marginBottom: '20px' }}>
              <button onClick={handlePrint} style={buttonStyle}>Print</button>
              <button onClick={handleDownloadPDF} style={buttonStyle}>Download as PDF</button>
            </div>
          )}

          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={headerCellStyle}>#</th>

                <th style={headerCellStyle}>Name</th>
                <th style={headerCellStyle}>Email</th>
                <th style={headerCellStyle}>Phone</th>
                <th style={headerCellStyle}>Company Name</th>
                <th style={headerCellStyle}>Department</th>
                <th style={headerCellStyle}>Monthly Salary</th>
              </tr>
            </thead>
            <tbody>
              {employeeData.map((emp, index) => (
                <tr key={emp.id}>
                  <td style={cellStyle}>{index + 1}</td>

                  <td style={cellStyle}>{emp.name}</td>
                  <td style={cellStyle}>{emp.email}</td>
                  <td style={cellStyle}>{emp.phone}</td>
                  <td style={cellStyle}>{emp.company_name}</td>
                  <td style={cellStyle}>{emp.department_name}</td>
                  <td style={cellStyle}>{emp.monthly_salary}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
     )}
    </div>
  );
};

// Styles for the overlay and popup
const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const popupStyle = {
  background: '#fff',
  borderRadius: '8px',
  boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  width: '90%',
  height: '80%',
  padding: '20px',
  overflowY: 'auto',
  maxHeight: '80vh',
  display: 'inline-block',
  margin: 'auto',
  fontSize: '12px',

};

const closeclour = {
  color: 'red'
}
// Button, table, and cell styles
const buttonStyle = {
  padding: '10px 20px',
  borderRadius: '5px',
  border: 'none',
  backgroundColor: '#4CAF50',
  color: '#fff',
  cursor: 'pointer',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.3s',
};

const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '20px',
};

const headerCellStyle = {
  padding: '12px',
  backgroundColor: '#f2f2f2',
  borderBottom: '1px solid #ddd',
  textAlign: 'left',
  fontWeight: 'bold',
};

const cellStyle = {
  padding: '10px',
  //   borderBottom: '1px solid #ddd',
  textAlign: 'left',
};

export default EmployeeDetails;
