import React, { useState, useEffect, useCallback } from 'react';
import './Viewattncal.css';
import { formatTimeatten } from '../../Component/TimeFormat';
import { deleteAttenApi, viewSingleAttendanceApi } from '../../../Config/api';
import { getUserDetails } from '../../../Services/Storage';
import { FaEdit } from 'react-icons/fa'; // Import edit icon from react-icons
import Editattendance from '../Add&editattdance/editattendance';
import { CiTrash } from 'react-icons/ci';
import DeleteAlert from '../../popup/DeleteAlert';

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const Calendar = ({ show, close, responseData, selMonth, selYear, staffId }) => {
  const [currentDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState([]);
  const [currentYear, setCurrentYear] = useState("");
  const [currentMonthIndex, setCurrentMonthIndex] = useState("");
  const [attendanceDetails, setAttendanceDetails] = useState([]);
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [holidayDetails, setHolidayDetails] = useState([]);
  const [lateData, setLateData] = useState([]);
  const formattedYear = currentYear;
  const user_details = getUserDetails();
  const [editattendance, seteditattendance] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [deleteComponentId, setDeleteComponentId] = useState(null);
  const [rowLoader, setRowLoader] = useState(false);

  useEffect(() => {
    if (responseData) {
      if (responseData.data) {
        setAttendanceDetails(responseData.data.attendance_details || []);
        setLeaveDetails(responseData.data.leave_details || []);
        setHolidayDetails(responseData.data.holiday_details || []);
        setLateData(responseData.data.late_details || []);
        const selectedYear = selYear || currentDate.getFullYear();
        const selectedMonth = selMonth ? selMonth - 1 : currentDate.getMonth();
        setCurrentYear(selectedYear);
        setCurrentMonthIndex(selectedMonth);
      }
      else {
        const selectedYear = selYear || currentDate.getFullYear();
        const selectedMonth = selMonth ? selMonth - 1 : currentDate.getMonth();
        setCurrentYear(selectedYear);
        setCurrentMonthIndex(selectedMonth);
      }
    }
  }, [responseData, selMonth, selYear, currentDate]);

  const generateCalendar = useCallback((year, month, attendanceDetails, leaveDetails, holidayDetails) => {
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const calendar = [];

    let week = [];
    for (let i = 0; i < firstDay; i++) {
      week.push(null);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      const attendance = Array.isArray(attendanceDetails) ? attendanceDetails.find(att => att.date === currentDate) : undefined;
      // const leave = Array.isArray(leaveDetails) ? leaveDetails.find(leave => leave.from_date === currentDate) : undefined;
      // const leave = Array.isArray(leaveDetails) ? leaveDetails.find(leave => leave.from_date === currentDate || leave.to_date === currentDate) : undefined;
      const leave = Array.isArray(leaveDetails)
        ? leaveDetails.find(leave =>
          new Date(leave.from_date) <= new Date(currentDate) &&
          new Date(leave.to_date) >= new Date(currentDate)
        )
        : undefined;

      const holiday = Array.isArray(holidayDetails)
        ? holidayDetails.find(holiday =>
          new Date(holiday.holiday_from_date) <= new Date(currentDate) &&
          new Date(holiday.holiday_end_date) >= new Date(currentDate)
        )
        : undefined;
      // const holiday = Array.isArray(holidayDetails) ? holidayDetails.find(holiday => holiday.holiday_date === currentDate)
      week.push({ day, attendance, leave, holiday });
      if (week.length === 7) {
        calendar.push(week);
        week = [];
      }
    }

    if (week.length > 0) {
      while (week.length < 7) {
        week.push(null);
      }
      calendar.push(week);
    }
    setCurrentMonth(calendar);
  }, []);

  useEffect(() => {
    generateCalendar(currentYear, currentMonthIndex, attendanceDetails, leaveDetails, holidayDetails);
  }, [currentYear, currentMonthIndex, attendanceDetails, leaveDetails, holidayDetails, generateCalendar]);

  const handlePrevMonth = async () => {
    const newDate = new Date(currentYear, currentMonthIndex - 1, 1);
    const year = newDate.getFullYear();
    const monthIndex = newDate.getMonth(); // Get the zero-based month index
    const month = (monthIndex + 1).toString().padStart(2, '0'); // Convert to one-based and pad
    try {
      setRowLoader(true);

      const response = await viewSingleAttendanceApi(`?register_id=${staffId}&month=${month}&year=${year}&admin_id=${user_details.id}`);
      if (response) {
        setAttendanceDetails(response.data.attendance_details || []);
        setLeaveDetails(response.data.leave_details || []);
        setHolidayDetails(response.data.holiday_details || []);
        setLateData(response.data.late_details || []);
        setCurrentYear(year);
        setCurrentMonthIndex(monthIndex); // Set as numeric index
      }
    } catch (error) {
    }finally{
      setRowLoader(false);

    }
  };

  const handleNextMonth = async () => {
    const newDate = new Date(currentYear, currentMonthIndex + 1, 1);
    const year = newDate.getFullYear();
    const monthIndex = newDate.getMonth(); // Get the zero-based month index
    const month = (monthIndex + 1).toString().padStart(2, '0'); // Convert to one-based and pad

    try {
      setRowLoader(true);

      const response = await viewSingleAttendanceApi(`?register_id=${staffId}&month=${month}&year=${year}&admin_id=${user_details.id}`);
      if (response) {
        setAttendanceDetails(response.data.attendance_details || []);
        setLeaveDetails(response.data.leave_details || []);
        setHolidayDetails(response.data.holiday_details || []);
        setLateData(response.data.late_details || []);
        setCurrentYear(year);
        setCurrentMonthIndex(monthIndex); // Set as numeric index
      }
    } catch (error) {
    }finally{
      setRowLoader(false);

    }
  };

  const openeditatt = (attendance) => {
    setSelectedAttendance(attendance);

    seteditattendance(true)
  }
  const closeeditatt = () => {
    seteditattendance(false)
  }
  const handledatafromchild = (updatedAttendance) => {
    console.log(updatedAttendance
    )

    if (updatedAttendance) {
      // Update the attendance details state
      setAttendanceDetails(prevDetails =>
        prevDetails.map(att => att.date === updatedAttendance.updated_attendance.date ? updatedAttendance.updated_attendance : att)
      );
      console.log(updatedAttendance)
       setLateData(updatedAttendance.late_details || []);
      // Regenerate the calendar with updated attendance
      generateCalendar(currentYear, currentMonthIndex, attendanceDetails, leaveDetails, holidayDetails);
    }
  };

  const showConfirmationModalDelete = (delid) => {
    setDeleteComponentId(delid);
    setConfirmationModalOpen(true);

  }
  const closeConfirmationModal = () => {
    setDeleteComponentId(null);
    setConfirmationModalOpen(false);
  };
  const confirmDelete = () => {
    if (deleteComponentId) {
      deleteattComponent(deleteComponentId);
      closeConfirmationModal();
    }
  }

  const deleteattComponent = async (componentId) => {
    try {
      const response = await deleteAttenApi(componentId);
      if (response) {
        setAttendanceDetails(prevDetails =>
          prevDetails.filter(att => att.id !== componentId)
        );
        // Regenerate the calendar with updated attendance
        generateCalendar(currentYear, currentMonthIndex, attendanceDetails, leaveDetails, holidayDetails);
      }
    }
    catch (error) {

    }
  }
  if (!show) return null;
  if (!responseData) return null;

  return (
    <div className='calendar-section1'>
      <div className="calendar">
        <span className='calendar-close' onClick={close}>&times;</span>
        <div className="calendar-header">
          {rowLoader?
          <span className='loader-pages-tablefield'></span>
          :
          <button onClick={handlePrevMonth}>&lt;</button>

          }
          <div>{new Date(currentYear, currentMonthIndex).toLocaleString('default', { month: 'long' })} {formattedYear}</div>
          {rowLoader?
          <span className='loader-pages-tablefield'></span>
          :<button onClick={handleNextMonth}>&gt;</button>


          }
        </div>
        <div className="calendar-body">
          <div className='calendar-body-first-child'>
            <div className="calendar-days">
              {daysOfWeek.map((day, index) => (
                <div key={index} className="calendar-day">
                  {day}
                </div>
              ))}
            </div>
            {currentMonth.map((week, weekIndex) => (
              <div key={weekIndex} className="calendar-week">
                {week.map((dayInfo, dayIndex) => (
                  <div
                    key={dayIndex}
                    className={`calendar-date ${dayInfo ? 'active' : ''} ${!dayInfo || (!dayInfo.attendance && !dayInfo.leave && !dayInfo.holiday) ? 'blurred' : ''}`}
                  >
                    {dayInfo ? (
                      <>
                        <div className='cal-day'>{dayInfo.day}</div>
                        {dayInfo.holiday?.reason ? (
                          <div className='cal-meeage holiday-cal-message'>Holiday {dayInfo.holiday.reason}</div>
                        ) : dayInfo.leave?.status ? (
                          dayInfo.leave.leave_deduct === 'Halfday' ?
                            (
                              <>
                                <div className='cal-meeage punched-cal-message'>{formatTimeatten(dayInfo.attendance?.punch_in_time)} - {formatTimeatten(dayInfo.attendance?.punch_out_time)}</div>
                                <div className='cal-meeage leave-cal-message'>{`${dayInfo.leave.leave_type} - ${dayInfo.leave.reason}`}</div>

                                {/* <span className='cal-message-working-hour'>{`W H : ${dayInfo.attendance.total_hr}`}</span> */}
                              </>
                            )
                            :
                            (
                              <div className='cal-meeage leave-cal-message'>{`${dayInfo.leave.leave_type} - ${dayInfo.leave.reason}`}</div>
                            )
                        ) : dayInfo.attendance ? (
                          <>
                            {user_details.role === 'Company' && (
                              <div className='edit-icon-cal'>
                                <FaEdit style={{ color: "#2a7ac7", cursor: "pointer", fontSize: "14px" }}
                                  onClick={() => openeditatt(dayInfo.attendance)}
                                  className=""
                                />
                                <CiTrash onClick={() => showConfirmationModalDelete(dayInfo.attendance.id)} style={{ color: "red", cursor: "pointer", fontSize: "14px" }} />                    </div>

                            )}


                            <div className='cal-meeage punched-cal-message'>{formatTimeatten(dayInfo.attendance?.punch_in_time)} - {formatTimeatten(dayInfo.attendance?.punch_out_time)}</div>
                            {/* <span className='cal-message-working-hour'>{`W H : ${dayInfo.attendance.total_hr}`}</span> */}
                          </>
                        ) : (
                          <div className='cal-meeage missedpunch-cal-message'>Missed Punch</div>
                        )}
                      </>
                    ) : null}
                  </div>
                ))}
              </div>

            ))}
            <div className='cal-footer-content-div'>
              <div className='cal-footer-content-div-child'>
                <span> Morning Late : {lateData.total_mrg_late}</span>
                <span> Early By : {lateData.total_evg_late}</span>
              </div>
              <div className='cal-footer-content-div-child'>
                <span> Total Late : {lateData.total_late_hour}</span>
              </div>
              <div className='cal-footer-content-div-child'>
                <span> Permission Hour : {lateData.permission_hour}</span>
                <span> Permission Exceed : {lateData.permission_exceed_time}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Editattendance
        show={editattendance}
        close={closeeditatt}
        datas={selectedAttendance}
        sendDataToParent={handledatafromchild}
      />
      <DeleteAlert
        isOpen={isConfirmationModalOpen}
        message="Really, would you like to remove this Attendance?"
        onCancel={closeConfirmationModal}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default Calendar;
