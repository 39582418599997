// src/MyMultiDatePicker.js
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-multi-date-picker';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import './DatePicker.css'; // Import custom styles if needed

const DateselectComp = ({ setSelectedDates }) => {
  const [dates, setDates] = useState([]);

  useEffect(() => {
    // Send the selected dates array to the parent component in ISO format
    const formattedDates = dates.map(date => new Date(date).toISOString().split('T')[0]);
    setSelectedDates(formattedDates);
  }, [dates, setSelectedDates]);

  const handleDateChange = (selectedDates) => {
    setDates(selectedDates);
  };

  return (
    <DatePicker
      multiple
      value={dates}
      onChange={handleDateChange}
      format="MMMM D, YYYY"
      editable={false} // Prevent typing in the input field
    />
  );  
};

export default DateselectComp;
