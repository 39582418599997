import React, { useState, useEffect } from 'react'
import '../../Style/Addemp.css';
import Header from '../../../Header/Header'
import "../../Style/Employeeform.css";
import Sidebar from '../../../Sidebar/Sidebar';
import { formatNumberDecimal } from '../../../Component/NumberFormatDecimal';
import { useSelector } from 'react-redux';
import { UpdateSalarydetailsApi } from '../../../../Config/api';
import { getCompanyDetails, getUserDetails } from '../../../../Services/Storage';
import { FaCircleUser } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { setUpdateSalaryInfo as SalaryInfoRedux } from '../../../../Slice/employeeSlice';
import { Store } from '../../../../Store';

const Editsalarydetails = () => {
   //  const navigate = useNavigate();
   //  const {Salarydetails, user_id} = location.state || {};
   const Salarydetails1 = useSelector((state) => state.employees);
   const [user_id, setUserId] = useState("");
   const [Empname, setEmpName] = useState("");
   const [Salarydetails, setSalarydetails] = useState(null);
   const [selectedSlacomp , setselectedSlacomp] = useState([]);
   useEffect(() => {
      if (Salarydetails1 && Salarydetails1.employees.length > 0) {
         setSalarydetails(Salarydetails1.employees[0]);
         setEmpName(Salarydetails1.employees[0].register_details.name);
         setUserId(Salarydetails1.employees[0].register_id);
      }
   }, [Salarydetails1]);
   const user_details = getUserDetails();
   const company_details = getCompanyDetails();
   const [errors, setErrors] = useState({});
   const [loading, setloading] = useState(false);
    const salaryComponentRedux = useSelector((state) => state.salarycomponent);
   const [salaryInfo, setSalaryInfo] = useState({
      annual_ctc: 0,
      monthly_salary: 0,
      salary_list: [],
      company: company_details.company_id,
      register: user_id,
      user: user_details.id,
      // eligible_salary:[],

   })

   // useEffect(() => {

   //    setSalaryInfo((state) => ({
   //       ...state,
   //       salary_list: Salarydetails1.salary_details.salary_list
   //       .map((component) => ({
   //          ...component,
   //          monthly: 0,
   //          yearly: 0,
   //       })),
   //    }));
      
   // }, [Salarydetails1.salary_details.salary_list]);
   // useEffect(() => {
   //    // Initialize salary_list with salaryComponentRedux data
   //    if (Salarydetails && Salarydetails.salary_details) {

   //    setSalaryInfo((state) => {
   //      const updatedSalaryList = salaryComponentRedux.map((component) => {
   //        // Find if the component exists in the current salary list
   //        const existingComponent = Salarydetails.salary_details.salary_list.find(
   //          (item) => item.id === component.id
   //        );
    
   //        if (existingComponent) {
   //          // If component exists, return it with monthly and yearly set to 0
   //          return {
   //            ...existingComponent,
   //            monthly: 0,
   //            yearly: 0,
   //          };
   //        } else {
   //          // If component does not exist, return the component with monthly and yearly set to 0
   //          return {
   //            ...component,
   //            monthly: 0,
   //            yearly: 0,
   //          };
   //        }
   //      });
   //  console.log("finally",updatedSalaryList)
   //      return {
   //        ...state,
   //        salary_list: updatedSalaryList ,
   //      };
   //    });
   // }
   //  }, [salaryComponentRedux]);
    

// useEffect(() => {
//    eligible_salary
// }, [])


   const handleInputChangectc = (event) => {
      const { value } = event.target;
      let intValue = 0;
      if (value > 0) {
         intValue = parseInt(value, 10);
      }
      else {
         intValue = '';
      }

      setSalaryInfo((state) => ({
         ...state,
         annual_ctc: intValue,
         monthly_salary: formatNumberDecimal(value / 12),
      }));

   }
   useEffect(() => {
      if (Salarydetails && Salarydetails.salary_details) {
         setSalaryInfo({
            annual_ctc: Salarydetails.salary_details?.annual_ctc || 0,
            monthly_salary: Salarydetails.salary_details?.monthly_salary || 0,
            // salary_list: Salarydetails.salary_details?.salary_list || [],
         });
         console.log(Salarydetails.salary_details)
         setselectedSlacomp(Salarydetails.salary_details.eligible_salary || []);
         setSalaryInfo((state) => {
            const updatedSalaryList = salaryComponentRedux.map((component) => {
              const existingComponent = Salarydetails.salary_details.salary_list.find(
                (item) => item.id === component.id
              );
        
              if (existingComponent) {
                return {
                  ...existingComponent,
                  monthly: 0,
                  yearly: 0,
                };
              } else {
                return {
                  ...component,
                  monthly: 0,
                  yearly: 0,
                };
              }
            });
            return {
              ...state,
              salary_list: updatedSalaryList ,
            };
          });
         
      }
   }, [Salarydetails,salaryComponentRedux]);
   useEffect(() => {
      const updatedSalaryComponentsCtc = salaryInfo.salary_list.map((component) => {
         if (component.id !== null) {
            let monthlyAmount = 0;
            let yearlyAmount = 0;
   
          //  if (component.calculation_code.toLowerCase() === 'basic') {
               // Calculation based on the basic component's yearly amount
               const basicComponent = salaryInfo.salary_list.find(comp => comp.component.toLowerCase() === 'basic');
               const basicAmount = basicComponent ? basicComponent.yearly : 0;
   
               if (component.calculation_type === 'Percentage of basic') {
                  yearlyAmount = (basicAmount * component.amount_type) / 100;
               } else if (component.calculation_type === 'Float amount') {
                  yearlyAmount = parseFloat(component.amount_type);
               }
          //  } else if (component.calculation_code.toLowerCase() === 'actual') {
               // Calculation based on the total CTC
               if (component.calculation_type === 'Percentage of CTC') {
                  yearlyAmount = (salaryInfo.annual_ctc * component.amount_type) / 100;
               } else if (component.calculation_type === 'Float amount') {
                  yearlyAmount = parseFloat(component.amount_type);
               }
          //  }
   
            monthlyAmount = yearlyAmount / 12;
            return {
               ...component,
               monthly: formatNumberDecimal(monthlyAmount),
               yearly: formatNumberDecimal(yearlyAmount),
            };
         }
         return component;
      });
   
      const isDifferent = JSON.stringify(salaryInfo.salary_list) !== JSON.stringify(updatedSalaryComponentsCtc);
   
      if (isDifferent) {
         setSalaryInfo((state) => ({
            ...state,
            salary_list: updatedSalaryComponentsCtc,
         }));
      }
   
   }, [salaryInfo.annual_ctc, salaryInfo.salary_list]);
   
   const totalMonthlyEarnings = salaryInfo.salary_list
   .filter(component => component.earning_deduction === 'earning' && selectedSlacomp.includes(component.id))
   .reduce((acc, component) => acc + parseFloat(component.monthly || 0), 0);
 
 const totalYearlyEarnings = salaryInfo.salary_list
   .filter(component => component.earning_deduction === 'earning' && selectedSlacomp.includes(component.id))
   .reduce((acc, component) => acc + parseFloat(component.yearly || 0), 0);
 
 // Calculate total monthly and yearly deductions
 const totalMonthlyDeductions = salaryInfo.salary_list
 .filter(component => component.earning_deduction === 'deduction'&& selectedSlacomp.includes(component.id) )
 .reduce((acc, component) => acc + parseFloat(component.monthly || 0), 0);

const totalYearlyDeductions = salaryInfo.salary_list
 .filter(component => component.earning_deduction === 'deduction'&& selectedSlacomp.includes(component.id))
 .reduce((acc, component) => acc + parseFloat(component.yearly || 0), 0);
 
   const costToCompanymonly = salaryInfo.monthly_salary;

   const costToCompany = salaryInfo.annual_ctc ;

   const validateInput = (name, value) => {
      let error = '';
      if (value.trim() === '') {
         error = 'This field is required';
      } else if (isNaN(value)) {
         error = 'Please enter a valid number';
      }
      return error;
   };
   const handleInputChange = (event, index) => {
      const { name, value } = event.target;
      const error = validateInput(name, value);

      setErrors((state) => ({
         ...state,
         [index]: error,
      }));

      const updatedSalaryComponents = salaryInfo.salary_list.map((component) => {

         if (component.id === index) {
            let monthlyAmount = 0;
            let yearlyAmount = 0;

          //  if (component.calculation_code.toLowerCase() === 'basic') {
               // Calculation based on the basic component's yearly amount
               const basicComponent = salaryInfo.salary_list.find(comp => comp.component.toLowerCase() === 'basic');
               const basicAmount = basicComponent ? basicComponent.yearly : 0;
   
               if (component.calculation_type === 'Percentage of basic') {
                  yearlyAmount = (basicAmount * component.amount_type) / 100;
               } else if (component.calculation_type === 'Float amount') {
                  yearlyAmount = parseFloat(component.amount_type);
               }
          //  } else if (component.calculation_code.toLowerCase() === 'actual') {
               // Calculation based on the total CTC
              else if (component.calculation_type === 'Percentage of CTC') {
                  yearlyAmount = (salaryInfo.annual_ctc * component.amount_type) / 100;
               } else if (component.calculation_type === 'Float amount') {
                  yearlyAmount = parseFloat(component.amount_type);
               }
           // }
   
            // Assuming 'percentage' and 'amount_type' exist in your component

            monthlyAmount = yearlyAmount / 12;
            return {
               ...component,
               amount_type: value,
               monthly: formatNumberDecimal(monthlyAmount),
               yearly: formatNumberDecimal(yearlyAmount),
            };
         }
         return component;
      });

      setSalaryInfo((state) => ({
         ...state,
         salary_list: updatedSalaryComponents,
      }));
   };



   //  const submitbutton =() =>{
   //  navigate('/addearningcomp')
   //  }

   const handlesubmit = async () => {
      console.log(selectedSlacomp,"array")
      let newErrors = {};
      const annualctc = String(salaryInfo.annual_ctc);
      if (annualctc.trim() === "") {
         salaryInfo.annual_ctc = "Enter Annual CTC"
      }
      if (selectedSlacomp.length === 0) {
         newErrors.selectedSlacomp = "Please select at least one salary component.";
      }
      const totalAnnualAmount = totalYearlyEarnings + totalYearlyDeductions;
      const isCtcExceeded = totalAnnualAmount > salaryInfo.annual_ctc;
    
      if (isCtcExceeded) {
        toast.error('The calculated amount exceeds the CTC limit.', {
          position: "top-center",
          autoClose: 2000,
          CloseButton: false,
          className: 'custom_toast',
        });
        return;
      }
      
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {

         try {
            setloading(true);

       const updatedSalaryList = salaryInfo.salary_list.map(salary => {
    if (selectedSlacomp.includes(salary.id)) {
        // Update logic (if needed)
        return {
            ...salary,
        };
    } else {
        // Remove logic (filter out the salary if the id is not in selectedSlacomp)
        return null;
    }
}).filter(salary => salary !== null); // Remove null values

const payload = {
    ...salaryInfo,
    eligible_salary: selectedSlacomp,
    salary_list: updatedSalaryList, // Updated list after adding/removing
};


            const user_id = Salarydetails.salary_details.id;
            const response = await UpdateSalarydetailsApi(user_id, JSON.stringify(payload));
            Store.dispatch(SalaryInfoRedux({ updatedData: response.data.updated_salary_details }));
            toast.success('Salary Info Updated Sucessfully!', {
               position: "top-center",
               autoClose: 2000,
               CloseButton: false,
               className: 'custom_toast'
            })
            //   if(response)

            //navigate('/addearningcomp')
            // Handle the response if needed
         } catch (error) {
            if (error.response && error.response.data) {
               const errorData = error.response.data.data;
               newErrors = {};
               for (const key in errorData) {
                  if (errorData.hasOwnProperty(key)) {
                     newErrors[key] = errorData[key];

                  }
               }
               setErrors(newErrors);

            } else {
               setErrors(newErrors);
            }
         } finally {
            setloading(false);

         }
      }
   };

   const handleCheckboxChange = (id) => {
      // const { checked } = e.target;
      // Update the corresponding salary component's `isChecked` state
      // If using state, you can update it like:


      setselectedSlacomp((prevState) =>
         prevState.includes(id)
           ? prevState.filter((empId) => empId !== id)
           : [...prevState, id]
       );
      console.log(id)

      // setSalaryInfo(prevState => ({
      //    ...prevState,
      //    salary_list: prevState.salary_list.map(item =>
      //       item.id === id ? { ...item, isChecked: checked } : item
      //    )
      // }));
      console.log(selectedSlacomp)
   };
   

   return (
      <div className='index-container'>
         <Header />
         <div className='index-content-parent'>
            <div className='index-content'>
               <Sidebar />
               <div className='index-content-sub'>
                  {/* <Header /> */}
                  <div className='index-main'>
                     <div className='addemp-subheader'>
                        <div className='addemp-subheader-sub'>
                           {/* <div className='addemp-subheader1'>
    <p> Add New Employee </p>
    <button className="pages-addempbutton">
    Employees
    </button>
    </div> */}
                           <div className='addemp-subheader2'>
                              <div className='editbasic-stepper'>
                                 < FaCircleUser className="editbasic-icon-large" />
                                 <p>{Empname}</p>
                              </div>
                           </div>

                        </div>
                     </div>
                     <div className='addemp-line'></div>
                     <div className='addemp-maincontent-sub'>
                        <div className='addemp-form form-padding'>
                           <div className='addemp-form-sub'>
                              <div className='addemp-form-sub-main'>
                                 <div className='salarydetails-card'>
                                    <div className='salarydetails-input-row'>
                                       <div className='salarydetails-input-rowsub'>
                                          <div className='salarydetails-input-group'>

                                             <p>Annual CTC <span className='emppersonalinfo-mandatory'>*</span></p>
                                          </div>
                                          <div className='salarydetails-input-group'>
                                             <div class="salarydetails-input-container">
                                                <input type="text" name='annual_ctc' value={salaryInfo.annual_ctc} onChange={handleInputChangectc} placeholder="Enter amount" />
                                                {errors.annual_ctc && (<div className='error-message-view'>{errors.annual_ctc}</div>)}
                                             </div>
                                          </div>
                                          <div className='salarydetails-input-group'>
                                             {/* <p>per year </p> */}
                                          </div>
                                       </div>
                                    </div>

                                    <div className='salarydetails-earning-dect'>
                                    <p className='salarydetails-earning-dect-note'><span className='emppersonalinfo-mandatory'>*</span> Note : Selected earnings and deductions will be calculated.</p>

                                       <table className='salary-table'>
                                          <tr>
                                             <th>SALARY COMPONENTS</th>
                                             <th>CALCULATION TYPE</th>
                                             <th>MONTHLY AMOUNT</th>
                                             <th>ANNUAL AMOUNT</th>
                                          </tr>
                                          <p className='salary-table-p'>Earnings:</p>

                                          {
                                             salaryInfo.salary_list
                                                .filter(sal_component => sal_component.earning_deduction === 'earning')
                                                .map((sal_component, index) => (
                                                   <tr key={index}>
                                                      <td>{sal_component.component_name}</td>
                                                      <td className='view-errorsal-input'>
                                                         <span>{sal_component.calculation_type}</span><br />
                                                         <div className='salry-comp-view-iput'>

                                                         <input type="text" value={sal_component.amount_type} 
                                                         name='special_all' 
                                                         disabled={!selectedSlacomp.includes(sal_component.id)} // Disable if checkbox is not checked
                                                         onChange={(e) => handleInputChange(e, sal_component.id)} 
                                                         placeholder="Enter value" />
                                                         <input
                  className='salary_check_input'
                  type="checkbox"
                  checked={selectedSlacomp.includes(sal_component.id)}
                  onChange={() => handleCheckboxChange(sal_component.id)} // Handle checkbox state change
               />
               </div>
                                                         {errors[sal_component.id] && (
                                                            <span className="error-message-view">{errors[sal_component.id]}</span>
                                                         )}
                                                      </td>
                                                      <td>{sal_component.monthly}</td>
                                                      <td>{sal_component.yearly}</td>
                                                   </tr>
                                                ))
                                          }
 <tr className='salarydetails-total-row'>
                  <td>Total Earnings</td>
                  <td></td>
                  <td>{formatNumberDecimal(totalMonthlyEarnings)}</td>
                  <td>{formatNumberDecimal(totalYearlyEarnings)}</td>
               </tr>
              
                                          <tr>
                                             <td></td>
                                             <td></td>
                                             <td></td>
                                             <td>
                                                {/* <button onClick={submitbutton} className='salarydetails-outline-button'>
             <FontAwesomeIcon icon={faPlus} />More
          </button> */}
                                             </td>
                                          </tr>

                                          <p>Deduction:</p>

                                          {
   salaryInfo.salary_list
      .filter(sal_component => sal_component.earning_deduction === 'deduction')
      .map((sal_component, index) => (
         <tr key={index}>
            <td>{sal_component.component_name}</td>
            <td className='view-errorsal-input'>
               <span>{sal_component.calculation_type}</span><br />
               <div className='salry-comp-view-iput'>
               <input
                  type="text"
                  value={sal_component.amount_type}
                  name='special_all'
                  onChange={(e) => handleInputChange(e, sal_component.id)}
                  placeholder="Enter value"
                  disabled={!selectedSlacomp.includes(sal_component.id)} // Disable if checkbox is not checked
               />
               <input
                  className='salary_check_input'
                  type="checkbox"
                  checked={selectedSlacomp.includes(sal_component.id)}
                  onChange={() => handleCheckboxChange(sal_component.id)}
               />
               </div>
             
               {errors[sal_component.id] && (
                  <span className="error-message-view">{errors[sal_component.id]}</span>
               )}
            </td>
            <td>{sal_component.monthly}</td>
            <td>{sal_component.yearly}</td>
         </tr>
      ))
}

  <tr className='salarydetails-total-row'>
                  <td>Total  Deductions</td>
                  <td></td>
                  <td>{formatNumberDecimal(totalMonthlyDeductions)}</td>
                  <td>{formatNumberDecimal(totalYearlyDeductions)}</td>
               </tr>
             <br/>
                                          <tr className='salarydetails-last-col'>
                                             <td>Actual Salary</td>
                                             <td></td>
                                             <td>{formatNumberDecimal(costToCompanymonly)}</td>
                                             <td>{formatNumberDecimal(costToCompany)}</td>                                          </tr>
                                       </table>
                                       <div>

                                       </div>
                                       <div className="error-message-view">
   {errors.selectedSlacomp && <span>{errors.selectedSlacomp}</span>}
</div>
                                       <div className='emppersonalinfo-submitbutton'>
                                          <button onClick={handlesubmit} disabled={loading}>{loading ? <span className='spinner'></span> : 'Submit'}</button>
                                       </div>
                                    </div>


                                 </div>

                              </div>
                           </div>

                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Editsalarydetails