import React, { useState, useEffect, useRef } from "react";
//import "../../AddEmployees/Basicdetails/Basicdetails.css";
import Sidebar from "../../../Sidebar/Sidebar";
import Header from "../../../Header/Header";
import "../../Style/Addemp.css";
import "../../Style/Employeeform.css";
import { FaCircleUser } from "react-icons/fa6";
import { convertTo12HourFormat } from "../../../Component/TimeFormat";
import { useSelector } from "react-redux";
import api_backend, { UpdateEmployeeBasicApi } from "../../../../Config/api";
import { Store } from "../../../../Store";
import { setUpdateEmployee as UpdateEmployeeRedux } from "../../../../Slice/employeeSlice";
import userdemoimg from "../../../../Images/Pagesimg/userdemo";
import { MultiSelect } from "react-multi-select-component";
import 'react-time-picker/dist/TimePicker.css';
import TimePicker from 'react-time-picker';
import { toast } from 'react-toastify';

const Editbasicinfo = () => {
  const basicInfo1 = useSelector((state) => state.employees);
  const [Empname, setEmpName] = useState("");
  const [basicInfo, setBasicInfo] = useState(null);
  useEffect(() => {
    if (basicInfo1 && basicInfo1.employees.length > 0) {
      setBasicInfo(basicInfo1.employees[0]);
      setEmpName(basicInfo1.employees[0].register_details.name);
    }
  }, [basicInfo1]);
  const companies = useSelector((state) => state.companies);
  const designationRedux = useSelector((state) => state.designations);
  const departmentRedux = useSelector((state) => state.department);
  const departmentHeadRedux = useSelector((state) => state.departmentHead);
  const departmentTlRedux = useSelector((state) => state.departmentTl);
  const [selectedimg, setselectedimg] = useState(null);
  const LeaveComponentRedux = useSelector((state) => state.leavecomponents);

  const [loading, setloading] = useState(false);
  const [errors, setErrors] = useState({});
  const [updateBasicInfo, setUpdateBasicInfo] = useState({
    emp_name: " ",
    work_email: " ",
    father_name: " ",
    dob: " ",
    gender: " ",
    blood_group: " ",
    emergency_contact: " ",
    doj: " ",
    // from_time           : " ",
    // to_time             : " ",
    work_location: " ",
    designation: " ",
    department: " ",
    depart_tl: " ",
    depart_head: " ",
    emp_id: " ",
    work_details: " ",
    permission_hr: null,
    staff_pic: " ",
    eligible_leave: [],
    work_from: null,
    work_to: null,
    company: "",
  });
  useEffect(() => {
    if (basicInfo) {
      setUpdateBasicInfo({
        emp_name: basicInfo.register_details.name || '',
        work_email: basicInfo.work_details.work_email || '',
        father_name: basicInfo.work_details.father_name || '',
        dob: basicInfo.work_details.dob || '',
        gender: basicInfo.work_details.gender || '',
        blood_group: basicInfo.work_details.blood_group || '',
        emergency_contact: basicInfo.work_details.emergency_contact || '',
        doj: basicInfo.work_details.doj || '',
        // from_time           : basicInfo.work_details.work_from,
        // to_time             : basicInfo.work_details.work_to,
        work_location: basicInfo.work_details.work_location || '',
        company: basicInfo.work_details.company || '',
        designation: basicInfo.work_details.designation || '',
        department: basicInfo.work_details.department || '',
        depart_tl: basicInfo.work_details.depart_tl || '',
        depart_head: basicInfo.work_details.depart_head || '',
        emp_id: basicInfo.work_details.emp_id || '',
        staff_pic: basicInfo.work_details.staff_pic || '',
        eligible_leave: basicInfo.work_details.eligible_leave || [],
        permission_hr: basicInfo.work_details.permission_hr || '',
        work_from: basicInfo.work_details.work_from || '',
        work_to: basicInfo.work_details.work_to || '',
      });

      // if(basicInfo.work_details.staff_pic){
      //   setselectedimg(basicInfo.work_details.staff_pic);
      // }
    }
  }, [basicInfo])
  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === 'time') {
      setUpdateBasicInfo((state) => ({
        ...state, [name]: convertTo12HourFormat(value),
      }));
    }
    else if (event.target.type === "file") {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        setUpdateBasicInfo((state) => ({
          ...state,
          [name]: file,
        }));
        setselectedimg(URL.createObjectURL(file)); // Set the selected image for preview

      } else {
        setselectedimg(null);

      }
    }
    else {
      setUpdateBasicInfo((state) => ({
        ...state, [name]: value,
        ...(name === "work_location" && { company: value })  // set `company` to match `work_location`

      }));
    }
  }
  const handleEligibleLeaveChange = (selected) => {
    setUpdateBasicInfo((state) => ({
      ...state,
      eligible_leave: selected.map((option) => option.value),
    }));
  };

  const handleKeyPress = (e) => {
    if (e.target.name === 'emp_id') {
      const specialCharPattern = /[!@#$%^&*(),.?":'=;{}|<>+_]/;
      if (specialCharPattern.test(e.key)) {
        e.preventDefault(); // Prevent input
      }
    }
  }

  const handlephonepress = (e) => {
    if (e.target.name === 'emergency_contact') {
      const specialCharPattern = /[^0-9]/g;
      if (specialCharPattern.test(e.key)) {
        e.preventDefault(); // Prevent input
      }
    }
  }
  const [maxDate, setMaxDate] = useState('');

  useEffect(() => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    setMaxDate(today.toISOString().split('T')[0]);
  }, []);
  const handleSubmit = async () => {
    // console.log(typeof updateBasicInfo.emergency_contact); // should log 'string'

    let newErrors = {};
    if (updateBasicInfo.emp_id.trim() === "") {
      newErrors.emp_id = "Employee Id is required";
    }
    if (!updateBasicInfo.work_email || updateBasicInfo.work_email.trim() === "") {
      newErrors.work_email = "Employee Email is required";
    } else if (
      !/^[a-zA-Z0-9]+([._%+-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
        updateBasicInfo.work_email.trim()
      )
    ) {
      newErrors.work_email = "Invalid email format";
    }

    if (updateBasicInfo.father_name.trim === "") {
      newErrors.father_name = "Father name is required";
    }
    if (updateBasicInfo.dob.trim() === "") {
      newErrors.dob = "Date of Birth is required";
    }
    if (updateBasicInfo.gender.trim() === "") {
      newErrors.gender = "Please Select Gender";
    }
    if (updateBasicInfo.blood_group.trim() === "") {
      newErrors.blood_group = "Blood Group is required";
    }
    const emergencyContact = String(updateBasicInfo.emergency_contact).trim();

    if (emergencyContact.trim() === "") {
      newErrors.emergency_contact = "Emergency Contact No is required";
    } else if (!/^\d{10}$/.test(updateBasicInfo.emergency_contact)) {
      newErrors.emergency_contact = "Phone Number must be exactly 10 digits";
    }
    if (updateBasicInfo.doj.trim() === "") {
      newErrors.doj = "Date of Joining is required"
    }
    if (updateBasicInfo.work_from === null) {
      newErrors.work_from = "Work From is required";
    }
    if (updateBasicInfo.work_to === null) {
      newErrors.work_to = "Work To is required"
    }
    const worklocat = String(updateBasicInfo.work_location).trim();

    if (worklocat === "") {
      newErrors.work_location = "Select Work Location"
    }
    const design = String(updateBasicInfo.designation).trim();
    if (design === "") {
      newErrors.designation = "Select Designation"
    }
    const depart = String(updateBasicInfo.department).trim();
    if (depart === "") {
      newErrors.department = "Select Department ";
    }
    // const dep_hed = String(updateBasicInfo.depart_head).trim();
    // if (dep_hed === "") {
    //   newErrors.depart_head = "Select Department Head"
    // }
    // const dep_tl = String(updateBasicInfo.depart_tl).trim();
    // if (dep_tl === "") {
    //   newErrors.depart_tl = "Select Department TL"
    // }
    if (updateBasicInfo.permission_hr === null) {
      newErrors.permission_hr = "Permission Hour is required"
    }
    if (updateBasicInfo.eligible_leave.length === 0) {
      newErrors.eligible_leave = "Select Eligible Leave"
    }
    // if (!updateBasicInfo.staff_pic) {
    //   newErrors.staff_pic = "Profile Image is Required"
    // }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setloading(true)
        const data = {
          ...updateBasicInfo,
          eligible_leave: JSON.stringify(updateBasicInfo.eligible_leave), // Convert only when sending
        };
        if (selectedimg) {
          data.staff_pic = updateBasicInfo.staff_pic;
        } else {
          delete data.staff_pic;
        }
        const response = await UpdateEmployeeBasicApi(basicInfo.work_details.id, data);
        if (response) {
          toast.success('Basic Details updated successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });
          Store.dispatch(UpdateEmployeeRedux({ updatedData: response.data.updated_staff, leaveData: response.data.eligible_leave_details[0] }));
          setselectedimg(null);
        }

      }
      catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          newErrors = {};

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              newErrors[key] = errorData[key];
            }
          }


          setErrors(newErrors);

        } else {
          toast.error('Failed.Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,

          });
          const newErrors = {
            response_error: [],

          };

          setErrors(newErrors);
          setUpdateBasicInfo((state) => ({
            ...state,
          }));
        }
      }
      finally {
        setloading(false)
      }
    }
  }
  const fileinput = useRef(null);
  const handleuploadimg = () => {
    fileinput.current.click();
  }
  const handlechangeper = (newtime) => {
    setUpdateBasicInfo((state) => ({
      ...state,
      permission_hr: newtime + ':00',
    })

    )
  }

  const handlefromtimechange = (newtime) => {
    setUpdateBasicInfo((state) => ({
      ...state,
      work_from: newtime + ':00',
    }))
  }

  const handletotimechange = (newtime) => {
    setUpdateBasicInfo((state) => ({
      ...state,
      work_to: newtime + ':00',
    }))
  }

  return (
    <div className="index-container">
      <Header />
      <div className="index-content-parent">
        <div className="index-content">
          <Sidebar />
          {basicInfo ? (
            <>
              <div className="index-content-sub">
                <div className="index-main">
                  <div className="addemp-subheader">
                    <div className="addemp-subheader-sub">
                      {/* <div className="addemp-subheader1">
<p>Edit Basic Information</p>
</div> */}
                      <div className="addemp-subheader2">
                        <div className="editbasic-stepper">
                          <FaCircleUser className="editbasic-icon-large" />
                          <p>{Empname}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="addemp-line"></div>

                  <div className="addemp-maincontent-sub">
                    <div className="addemp-form form-padding">
                      <div className="addemp-form-sub">
                        <div className='addemp-form-sub-main'>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-imgemp">
                              <div className="emppersonalinfo-input-imgemp-sub">

                                <div className="emppersonalinfo-input-imgemp-main" onClick={handleuploadimg}>
                                  {selectedimg ? (<img src={selectedimg} alt="demo" />
                                  )
                                    : updateBasicInfo.staff_pic ? (
                                      <img src={`${api_backend.BACKEND_API}${updateBasicInfo.staff_pic}`} alt="Employee" />

                                    ) :
                                      (<img src={userdemoimg.demo_img} alt="demo" />
                                      )
                                  }
                                  <input type="file" style={{ display: 'none' }} name="staff_pic" ref={fileinput} onChange={handleInputChange} />

                                </div>

                              </div>
                              <label className='label-fields'>Upload Image </label>

                              {/* {errors.staff_pic && (<div className='error-message-view'>{errors.staff_pic}</div>)} */}

                            </div>
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Employee Name
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                placeholder="Enter employee name"
                                type="text"
                                name="emp_name"
                                onChange={handleInputChange}
                                value={updateBasicInfo.emp_name}
                                disabled
                              />
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Employee ID
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                placeholder="Enter employee id"
                                type="text"
                                onKeyPress={handleKeyPress}
                                name="emp_id"
                                onChange={handleInputChange}
                                value={updateBasicInfo.emp_id}
                              />
                              {errors.emp_id && (<div className="error-message-view">{errors.emp_id}</div>)}
                            </div>
                          </div>

                          <div className='basicdetails-input-col'>
                            <div className='basicdetails-col-view'>
                              <label>Work Email <span className='emppersonalinfo-mandatory'>*</span></label>
                              <input className='emppersonalinfo-inputfield-col'   onKeyDown={(e) => {
      const regex = /^[a-zA-Z0-9@._\b]+$/; // Allowed characters: alphanumeric, '@', '.', and '_'
      if (!regex.test(e.key)) {
        e.preventDefault(); // Prevents the key press if it's not allowed
      }
    }} placeholder='abc@xyz.com' type='text' value={updateBasicInfo.work_email} name="work_email" onChange={handleInputChange} />
                            </div>
                            {errors.work_email && (<div className="error-message-view">{errors.work_email}</div>)}
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Father Name
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="text"
                                name="father_name"
                                value={updateBasicInfo.father_name}
                                onChange={handleInputChange}
                              />
                              {errors.father_name && (<div className="error-message-view">{errors.father_name}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Date Of Birth
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="date"
                                name="dob"
                                max={maxDate}
                                value={updateBasicInfo.dob}
                                onChange={handleInputChange}
                              />
                              {errors.dob && (<div className="error-message-view">{errors.dob}</div>)}
                            </div>
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Gender
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <select onChange={handleInputChange} className="emppersonalinfo-input-field3" name="gender">
                                <option value={updateBasicInfo.gender}>{updateBasicInfo.gender}</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                              </select>
                              {errors.gender && (<div className="error-message-view">{errors.gender}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Blood Group
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="text"
                                name="blood_group"
                                value={updateBasicInfo.blood_group}
                                onChange={handleInputChange}
                              />
                              {errors.blood_group && (<div className="error-message-view">{errors.blood_group}</div>)}
                            </div>
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Emergency Contact No
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="text"
                                name="emergency_contact"
                                onKeyPress={handlephonepress}
                                value={updateBasicInfo.emergency_contact}
                                onChange={handleInputChange}
                              />
                              {errors.emergencyContact && (<div className="error-message-view">{errors.emergency_contact}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Date Of Joining
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="date"
                                name="doj"
                                value={updateBasicInfo.doj}
                                onChange={handleInputChange}
                              />
                              {errors.doj && (<div className="error-message-view">{errors.doj}</div>)}
                            </div>
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Permission Hour<span className="emppersonalinfo-mandatory">
                                  *
                                </span>
                              </label>
                              <TimePicker
                                onChange={handlechangeper}
                                value={updateBasicInfo.permission_hr}
                                className="time-picker"
                                format="HH:mm"
                                clockIcon={null}
                              />
                              {errors.permission_hr && (<div className="error-message-view">{errors.permission_hr}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Leave Eligible
                                <span className="emppersonalinfo-mandatory">
                                  *
                                </span>
                              </label>
                              <MultiSelect
                                options={
                                  LeaveComponentRedux.map(leavecomponent => ({
                                    label: leavecomponent.leave_type,
                                    value: leavecomponent.id
                                  }))
                                }
                                value={updateBasicInfo.eligible_leave.map(id => ({
                                  label: LeaveComponentRedux.find(leave => leave.id === id)?.leave_type,
                                  value: id
                                }))}
                                onChange={handleEligibleLeaveChange}
                              />
                              {errors.eligible_leave && (<div className="error-message-view">{errors.eligible_leave}</div>)}
                            </div>
                          </div>
                          <p className='emp-work-p'>Work Timing:</p>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                From Time
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>

                              <TimePicker
                                onChange={handlefromtimechange}
                                className="time-picker"
                                value={updateBasicInfo.work_from}
                              />
                              {errors.work_from && (<div className="error-message-view">{errors.work_from}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                To Time
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>

                              <TimePicker
                                onChange={handletotimechange}
                                value={updateBasicInfo.work_to}
                                className="time-picker"
                              />
                              {errors.work_to && (<div className="error-message-view">{errors.work_to}</div>)}
                            </div>
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Work Location
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <select onChange={handleInputChange} className="emppersonalinfo-input-field3" name="work_location" value={updateBasicInfo.work_location}>
                                {companies.map((company, index) => (
                                  <option key={index} value={company.id}>
                                    {company.company_name}
                                  </option>
                                ))}
                              </select>
                              {errors.work_location && (<div className="error-message-view">{errors.work_location}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Designation
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <select onChange={handleInputChange} className="emppersonalinfo-input-field3" name="designation" value={updateBasicInfo.designation}>


                                {designationRedux.map((designation, index) => (
                                  <option key={index} value={designation.id}>
                                    {designation.designation}
                                  </option>
                                ))}
                              </select>
                              {errors.designation && (<div className="error-message-view">{errors.designation}</div>)}
                            </div>

                          </div>
                          <div className='emppersonalinfo-input-row'>
                            <div className='emppersonalinfo-input-group'>
                              <label>Department<span className='emppersonalinfo-mandatory'>*</span></label>

                              <select name='department' value={updateBasicInfo.department} onChange={handleInputChange} className='emppersonalinfo-input-field3'>
                                <option value=''>Select Department</option>
                                {departmentRedux.map((department, index) => (
                                  <option key={index} value={department.id}>
                                    {department.depart_name}
                                  </option>
                                ))}
                              </select>
                              {errors.department && (<div className="error-message-view">{errors.department}</div>)}
                            </div>
                            <div className='emppersonalinfo-input-group'>
                              <label>Department Head <span className='emppersonalinfo-mandatory'>*</span></label>
                              <select name='depart_head' value={updateBasicInfo.depart_head} onChange={handleInputChange} className='emppersonalinfo-input-field3'>
                                <option value=''>Select Department Head</option>
                                {departmentHeadRedux.map((department_head, index) => (
                                  <option key={index} value={department_head.row_id}>
                                    {department_head.depart_head_name}
                                  </option>
                                ))}
                              </select>
                              {errors.depart_head && (<div className="error-message-view">{errors.depart_head}</div>)}
                            </div>
                            <div className='emppersonalinfo-input-group'>
                              <label>Department TL
                                <span className='emppersonalinfo-mandatory'>*</span></label>

                              <select name='depart_tl' value={updateBasicInfo.depart_tl} onChange={handleInputChange} className='emppersonalinfo-input-field3'>
                                <option value=''>Select Department TL</option>
                                {departmentTlRedux.map((department_tl, index) => (
                                  <option key={index} value={department_tl.row_id}>
                                    {department_tl.depart_tl_name}
                                  </option>
                                ))}
                              </select>
                              {errors.depart_tl && (<div className="error-message-view">{errors.depart_tl}</div>)}
                            </div>
                          </div>
                          <div className="emppersonalinfo-submitbutton">
                            <button onClick={handleSubmit} disabled={loading}> {loading ? <span className="spinner"></span> : 'Submit'}</button>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Editbasicinfo;
