import React, { useState, useEffect, useCallback } from 'react'
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import Select from 'react-select';
import { CiEdit, CiSearch } from 'react-icons/ci';
import './Viewpayroll.css'
import { useNavigate } from 'react-router-dom';
import getMonthYearData from '../../Component/MonthYearList';
import { CompanyApi } from '../../../Config/CommonApi';
import { AddsalaryCalculationApi, DeleteSalaryList, salaryStatusApproveApi, Viewpayrolledit, viewSingleStaffSalaryApi, viewStaffSalaryApi } from '../../../Config/api';
import { getUserDetails } from '../../../Services/Storage';
import pageloader from '../../../Loader/pageloading/Pageloader';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import ReactPaginate from "react-paginate";
import Confirmation from '../../Component/Confirmation';
import { toast } from 'react-toastify';
import leaveStatusalert from '../../Leave/Leavestatusalertview';
import DeleteAlert from '../../popup/DeleteAlert';
import Editpayroll from '../Editpayroll/Editpayroll';

const Viewpayroll = () => {
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [currentpage, setCurrentPage] = useState(1);
  const per_page = 10;
  const [companyData, setCompanyData] = useState([]);
  // const [designationData, setDesignationData] = useState([]);
  const [company, setCompany] = useState("");
  // const [designation, setDesignation] = useState("");
  const [search, setSearchword] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, '0'));
  const [employees, setEmployees] = useState([]);
  const user_details = getUserDetails();
  const [companyFilter, setCompanyFilter] = useState([]);
  // const [designationfliter, setdesignationfilter] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalLeaveCount, setTotalLeaveCount] = useState(0);
  const [showConfirmModalst, setShowConfirmModalst] = useState(false);
  const [statusToUpdate, setStatusToUpdate] = useState(null);
  const [showeditpayroll ,setshoweditpayroll] = useState(false);
  const [slipData, setslipData] = useState([]);
  const [rowLoader, setRowLoader] = useState({});
  const [rowLoaderemp, setRowLoaderemp] = useState({});
  const [rowLoadersalary, setRowLoadersalary] = useState({});


  const handleCloseModal = () => {
    setShowConfirmModal(false);
  };

  const handleCloseModalst = () => {
    setShowConfirmModalst(false);
  };

  const handeactionbutton = () => {
    setShowConfirmModal(true);

  }
  const handleChangeCompany = (selecteOption) => {
    setCompany(selecteOption.value);
    setCompanyFilter(selecteOption);
    setCurrentPage(1)


  };
  // const handleChangeDesignation = (selecteOption) => {
  //   setDesignation(selecteOption.value);
  //   setdesignationfilter(selecteOption);
  //   setCurrentPage(1)


  // };
  const handleChangeYear = (selecteOption) => {
    setCurrentPage(1)

    setYear(selecteOption.value);
  }
  const handleChangeMonth = (selecteOption) => {
    setCurrentPage(1)

    setMonth(selecteOption.value);
  }
  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchword(value);
  }

  const handleupdatesta = async (status) => {
    setStatusToUpdate(status);
    setShowConfirmModalst(true);
  }
  useEffect(() => {
    const fetchCompanyData = async () => {
      const data = await CompanyApi();
      setCompanyData(data);
      setCompanyFilter(data[0]);

    };
    const fetchDesignationData = async () => {
      // const data = await DesignationApi();
      // setDesignationData(data);
      // setdesignationfilter(data[0]);

    };
    fetchCompanyData();
    fetchDesignationData();
  }, []);

  const { months, years } = getMonthYearData();
  const monthData = Object.keys(months).sort((a, b) => a - b).map(key => (
    {
      value: key,
      label: months[key],
    }));

  const yearData = Object.keys(years).map(key => ({
    value: years[key],
    label: years[key],
  }));

  useEffect(() => {
    const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');  // getMonth returns 0 for January, so add 1
    const currentYear = new Date().getFullYear().toString();     // Get the full year
    setMonth(currentMonth);
    setYear(currentYear);
  }, []); // Empty dependency array ensures this runs once on mount

  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [salarySelectedEmployees, setSalaryselectedEmployess] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [confirmationmodelopen, setconfirmationmodelopen] = useState(false);
  const [showApprovalDropdown, setShowApprovalDropdown] = useState(false);
  const [loadingEmployees, setLoadingEmployees] = useState([]); // Tracks IDs of employees being updated

  const handleCheckboxChange = (employee_details) => {
    setSelectedEmployees(prevState =>
      prevState.includes(employee_details.register_id) ? prevState.filter(empId => empId !== employee_details.register_id) : [...prevState, employee_details.register_id]
    );
    setSalaryselectedEmployess(prevState => {
      if (employee_details.salary_status) {
        return prevState.includes(employee_details.salary_status.id)
          ? prevState.filter(empId => empId !== employee_details.salary_status.id)
          : [...prevState, employee_details.salary_status.id];
      }
      return prevState; // If salary_status is not available, return the previous state without changes
    });
  };

  const handleAllCheckboxChange = () => {
    if (selectAll) {
      setSelectedEmployees([]);
      setSalaryselectedEmployess([]);
    } else {
      setSelectedEmployees(employees.map(employee => employee.register_id));
      setSalaryselectedEmployess(
        employees.map(employee => 
          employee.salary_status && employee.salary_status.id ? employee.salary_status.id : null
        ).filter(id => id !== null)
      );
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selectedEmployees.length === employees.length) {
      setSelectAll(true); // Set the selectAll state to true if all employees are selected
    } else {
      setSelectAll(false); // Otherwise, set it to false
    }
  }, [selectedEmployees, employees.length]);

  const handleApprovalButtonClick = () => {
    setShowApprovalDropdown(prevState => !prevState);
  };

  const handleApproveSalary = async () => {
    console.log(salarySelectedEmployees)
    setShowConfirmModalst(false);
    if (selectedEmployees) {
      try {
        setLoadingEmployees(salarySelectedEmployees); // Show loader for these IDs

        const data = {
          "id_list": salarySelectedEmployees,
          "status": statusToUpdate,
          "register_id": selectedEmployees,
          "month": month,
          "year": year
        }
        const response = await salaryStatusApproveApi(JSON.stringify(data));

        if (response) {
          setSelectedEmployees([])
          setSalaryselectedEmployess([])
          setSelectAll(false)
          // fetchpayrollList();
          const updatedArray = new Map(response.data.merged_data.map(item => [item.register_id, item]));

          // Merge the updated data into the original array
          const updatedEmployees = employees.map(employee =>
            updatedArray.has(employee.register_id)
              ? { ...employee, ...updatedArray.get(employee.register_id) }
              : employee
          );
          setEmployees(updatedEmployees);
          
          toast.success('Updated successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });

        }

      }
      catch (error) {
        toast.error('Failed. Please try again.', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,

        });
      }finally{
        setLoadingEmployees([]); // Clear loader state

      }
    }
  }

  const salaryCalculation = async () => {
    setShowConfirmModal(false);
    if (selectedEmployees) {
      try {
        setRowLoadersalary(selectedEmployees);

        const data = {
          "register_id": selectedEmployees,
          "month": month,
          "year": year
        }
        const response = await AddsalaryCalculationApi(JSON.stringify(data));
        if (response) {

          const updatedArray = new Map(response.data.calculation.map(item => [item.register_id, item]));

          // Merge the updated data into the original array
          const updatedEmployees = employees.map(employee =>
            updatedArray.has(employee.register_id)
              ? { ...employee, ...updatedArray.get(employee.register_id) }
              : employee
          );
          setSelectedEmployees([])
          setSalaryselectedEmployess([])
          setSelectAll(false)
          // fetchpayrollList();
          setEmployees(updatedEmployees);

          toast.success('Salary added successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });
        }
      }
      catch (error) {
        toast.error('Failed. Please try again.', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,

        });
      }finally{
        setRowLoadersalary([]);
      }
    }
  }

  //const employees = payroll_list.employee_salary_details;
  const opensalaryslip = async (id, name) => {
    try {
      setRowLoaderemp((prev) => ({ ...prev, [id]: true }));

      const response = await viewSingleStaffSalaryApi(`${id}/?admin_id=${user_details.id}`);
      if (response) {
        navigate('/viewsalaryslip', { state: { data: response.data, userName: name } });
        // navigate('/basicdetails', {state: {name : response.user_details.name, user_id : response.user_details.id}});
      }
    }
    catch (error) {
    }finally{
      setRowLoaderemp((prev) => ({ ...prev, [id]: false }));

    }
  }
  const fetchpayrollList = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await viewStaffSalaryApi(`?register_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&search=${search}&company=${company}&month=${month}&year=${year}`);
      if (response) {
        setEmployees(response.data.employee_salary_details);
        setTotalLeaveCount(response.data.page_context.total);

      }
    }
    catch (error) {
    } finally {
      setIsLoading(false);

    }
  }, [currentpage, per_page, search, company, month, year, user_details.id]);
  useEffect(() => {
    fetchpayrollList();
  }, [fetchpayrollList])


  const closeDropdowns = (e) => {
    // Check if the clicked element is inside any of the dropdowns
    if (
      e.target.closest(".pages-action-button-payroll")
    ) {
      // Do not close dropdowns if the click is inside them
      return;
    }

    // Close all dropdowns
    setShowApprovalDropdown(false);
  };

  useEffect(() => {
    // Add a click event listener to the window
    window.addEventListener("click", closeDropdowns);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("click", closeDropdowns);
    };
  }, [
  ]);
  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

const showdeleteconfirmmodel =()=>{
console.log(selectedEmployees,salarySelectedEmployees)
setconfirmationmodelopen(true)

  }

const closeconfirmation =()=>{
  setconfirmationmodelopen(false)
}

const confirmDelete = async()=>{
//DeleteSalaryList
try{
const data = {
  "id_list": salarySelectedEmployees,

  "register_id": selectedEmployees,
  "month": month,
  "year": year
}

const response = await DeleteSalaryList(JSON.stringify(data));
if(response){
  closeconfirmation();
  fetchpayrollList();
}
}catch{

}
}

// const openeditsalry = (id) => {
//   const data = {
//     register_id: [id],
//     month: month,
//     year: year,
//   };
//   console.log('Data sent to Editpayroll:', data);
//   setdataviewedit(data);
//   setshoweditpayroll(true);
// };
const openeditsalry = async (id) => {
    const data = {
    register_id: [id],
    month: month,
    year: year,
  };
  try {
    setRowLoader((prev)=>({...prev ,[id]:true}));

      const response = await Viewpayrolledit(data);
      if (response) {
       
          setslipData(response.display_salary_details_single_person[0])
          // showpopupslip(true);
           setshoweditpayroll(true);

      }
  }
  catch (error) {

  }finally{
    setRowLoader((prev) => ({ ...prev, [id]: false }));
 
  }
}

const closeeditpop =()=>{
  setshoweditpayroll(false);
}

const handleupdated =(data)=>{
if(data){
  fetchpayrollList();
}
}
  return (
    <>
      <div className='index-container'>
        <Header />
        <div className='index-content-parent'>
          <div className='index-content'>
            <Sidebar />
            <div className='index-content-sub'>
              <div className='index-main'>
                <div className='pages-subheader'>
                  {selectedEmployees.length === 0 ? (
                    <>
                      <div className='pages-subheader-sub-1'>
                        <p>Payroll</p>
                        <span className='header-search-add-parent'>
                          <div className='pages-serach-view'>
                            <CiSearch className='page-search-icon' />
                            <input className='pages-serach' onChange={handleInputChange} placeholder='Search Payroll' type='search' />
                          </div>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='pages-subheader-sub-2 payroll-salary-calculation-div'>
                        <div className='payroll-button-div'>
                          <button className='pages-action-button-payroll' onClick={handeactionbutton}>Salary Calculation</button>
                        </div>

                        <div className='payroll-button-div'>
                          <button className='pages-action-button-payroll' onClick={handleApprovalButtonClick}>Salary Approval</button>

                          {showApprovalDropdown && (
                            <div className='approval-dropdown-payroll'>
                              <ul>
                                <li onClick={() => handleupdatesta('1')}>Approve</li>
                                <li onClick={() => handleupdatesta('2')}>Reject</li>
                                <li onClick={() => handleupdatesta('3')}>Hold</li>
                                <li onClick={() => handleupdatesta('0')}>Pending</li>
                              </ul>
                            </div>
                          )}
                        </div>
                        <div className='payroll-button-div'>
                          <button className='pages-action-buttondel-payroll' onClick={showdeleteconfirmmodel} >Delete</button>
                        </div>
                      </div>

                    </>
                  )}
                </div>
                <div className='pages-line'></div>
                <div className='pages-filter-options'>
                  <div className='pages-filter-sub'>
                    <p>Filter:</p>
                    <Select className='pages-dropdown1' options={companyData} value={companyFilter}
                      onChange={handleChangeCompany} placeholder="Select Work Location" />
                    {/* <Select className='pages-dropdown' value={designationfliter} options={designationData}
                      onChange={handleChangeDesignation} placeholder="Select Designation" /> */}
                    <Select className='pages-dropdown' options={yearData} value={yearData.find(option => option.value === year)}

                      onChange={handleChangeYear}
                      placeholder="Select Year" />
                    <Select className='pages-dropdown' options={monthData} onChange={handleChangeMonth} value={monthData.find(option => option.value === month)} placeholder="Select Month" />

                  </div>
                </div>
                <div className='pages-maincontent'>
                  {isLoading ? (
                    <div className="loader-container">
                      <img src={pageloader.page_loader} alt="Loading..." />
                    </div>
                  ) : (
                    <table className='pages-table'>
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleAllCheckboxChange}
                            />
                          </th>
                          <th>Emp Id</th>
                          <th>Name</th>
                          <th>Salary Status</th>
                          <th>Salary Report</th>
                          <th>#</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employees.length > 0 ? (
                          
                          employees.map(employee => (
                            
                            <tr key={employee.id}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedEmployees.includes(employee.register_id)}
                                  onChange={() => handleCheckboxChange(employee)}
                                />
                              </td>
                              <td>
                              {Array.isArray(rowLoadersalary) && rowLoadersalary.includes(employee.register_details.id) ? (
                              <span className='loader-pages-tablefield'></span>
                              ):
                                (
                                  <> {employee.work_details.emp_id}</>

                                )
                                  
                                }
                               
                                </td>
                              <td>{employee.register_details.name}</td>
                              <td
                                style={{
                                  color: employee.salary_status
                                    ? employee.salary_status.status === "1"
                                      ? "green"
                                      : employee.salary_status.status === "2"
                                        ? "red"
                                        : employee.salary_status.status === "3"
                                          ? "orange"
                                          : "black"
                                    : "black",
                                }}
                              >
                               

                                {loadingEmployees.includes(employee.salary_status?.id
)?(<><span className='loader-pages-tablefield'></span></>)
:(<> {employee.salary_status ? (
  employee.salary_status.status === "0"
    ? "Pending"
    : employee.salary_status.status === "1"
      ? "Approve"
      : employee.salary_status.status === "2"
        ? "Reject"
        : employee.salary_status.status === "3"
          ? "Hold"
          : "N/A"
) : "N/A"}</>)

                                }
                              </td>

                              <td >
                                {rowLoaderemp[employee.register_details.id]?<span className='loader-pages-tablefield'></span>
                                :
                                <div className='viewattn-view-button' onClick={() => opensalaryslip(employee.register_details.id, employee.register_details.name)}>View</div>

                                }
                              </td>
                              <td>
                                { employee.salary_status?.status !== "1" &&(
                                  rowLoader[employee.register_details.id]?(

                                    <span className='loader-pages-tablefield'></span>

                                  ):(
                                    <CiEdit style={{ fontSize: "18px", color: "#2a7ac7" }}  onClick={()=>openeditsalry(employee.register_details.id)}/>

                                  )

                                  

                                )
                                  
                                }
                              </td>
                                {/* <td>
                                <CiTrash  style={{ color: "red", cursor: "pointer", fontSize: "14px" }} />
                                </td> */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="no-data-table">
                              <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />
                            </td>
                          </tr>
                        )
                        }
                      </tbody>
                    </table>
                  )}
                  <div style={{ float: "right" }} id="pagination_link"></div>
                  <div style={{ padding: "20px" }}>
                    {(!isLoading)&&(totalLeaveCount > per_page) ? (
                      <>
                        <ReactPaginate
                          pageCount={Math.ceil(totalLeaveCount / per_page)}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          onPageChange={currentPageNo}
                          forcePage={currentpage - 1} // Adjust index (0-based)

                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </>
                    ) : (
                      <> </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Confirmation
          isOpen={showConfirmModal}
          onClose={handleCloseModal}
          onConfirm={salaryCalculation}
          message={`Are you sure you want to calculate the salary?`}
        />
        <Confirmation
          isOpen={showConfirmModalst}
          onClose={handleCloseModalst}
          onConfirm={handleApproveSalary}
          message={`Are you sure you want to ${leaveStatusalert(String(statusToUpdate))} the selected Payroll?`}
        />
        <DeleteAlert
        isOpen={confirmationmodelopen}
        message={"Really, would you like to remove this Slip?"}
        onCancel={closeconfirmation}
        onConfirm={confirmDelete}
        />
        <Editpayroll
        salarydata={slipData}
        show={showeditpayroll}
        close={closeeditpop}
        datatoparent ={handleupdated}
          />
      </div>
    </>
  )
}

export default Viewpayroll
