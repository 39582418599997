import React, { useState, useEffect, useCallback } from 'react';
import '../Earnings/Earnings.css';
import { Link } from 'react-router-dom';
import { CiEdit, CiSearch, CiTrash } from 'react-icons/ci';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import Adddeduction from './Adddeduction';
// import { useSelector } from 'react-redux';
import { deleteSalaryComponentApi, viewSalaryComponentDeductionApi } from '../../../Config/api';
import { getUserDetails } from '../../../Services/Storage';
import DeleteAlert from '../../popup/DeleteAlert';
import Editdeduction from './Editdeduction';
import pageloader from '../../../Loader/pageloading/Pageloader';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import ReactPaginate from "react-paginate";

const Deductions = () => {
  const user_details = getUserDetails();
  // const salaryComponentRedux = useSelector((state) => state.salarycomponent);
  const [deductioncomp, setDeductionComp] = useState([]); // Initialize as an empty array
  const [loading, setIsloading] = useState(false);
  const [totaldedcount, setdedcount] = useState(0)
  const per_page = 10;
  const [currentpage, setCurrentPage] = useState(1);
  const [search, setSearchword] = useState("");

  const fetchSalaryComponentDeduction = useCallback(async () => {
    try {
      setIsloading(true);
      const response = await viewSalaryComponentDeductionApi(`?register_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&search=${search}`);
      if (response) {
        setDeductionComp(response.data.get_salary_component_deduction || []); // Ensure response data is an array
        setdedcount(response.data.page_context.total)
      }
    } catch (error) {
    } finally {
      setIsloading(false);
    }
  }, [currentpage, search, user_details.id]);
  const handleDataFromChild = (data) => {
    setDeductionComp((prevcompArray) => [
      data,
      ...prevcompArray,
    ]);
  };
  useEffect(() => {
    fetchSalaryComponentDeduction();
  }, [fetchSalaryComponentDeduction]);

  const [isopendeduction, setopendeduction] = useState(false);
  const opendeduction = () => {
    setopendeduction(true);
  };
  const closededuction = () => {
    setopendeduction(false);
  };

  const [editDeduction, setEditDeduction] = useState(false);
  const [editData, setEditData] = useState([]);

  const editDeductionButton = (componentId) => {
    const updatedList = deductioncomp.filter((component) => component.id === componentId);
    if (updatedList) {
      setEditData(updatedList[0]);
      setEditDeduction(true);
    }
  }

  const closeEditDeduction = () => {
    setEditDeduction(false);
  }

  const handleUpdateData = (data) => {
    const updatedList = deductioncomp.map((comp) =>
      comp.id === data.id ? data : comp
    );
    setDeductionComp(updatedList);
  }

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [deleteComponentId, setDeleteComponentId] = useState(null);

  const showConfirmationModalDelete = (InvoiceId) => {
    setDeleteComponentId(InvoiceId);
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setDeleteComponentId(null);
    setConfirmationModalOpen(false);
  };
  const handleSearch = (event) => {
    setSearchword(event.target.value);
  }
  const confirmDelete = () => {
    if (deleteComponentId) {
      deletesalaryComponent(deleteComponentId);
      closeConfirmationModal();
    }
  };
  const deletesalaryComponent = async (componentId) => {
    try {
      const response = deleteSalaryComponentApi(componentId);
      if (response) {
        const updatedList = deductioncomp.filter((component) => component.id !== componentId);
        setDeductionComp(updatedList);
      }
    }
    catch (error) {

    }
  }
  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  return (

    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>


                <div className='pages-subheader-sub-1'>
                  <p>Salary Component </p>
                  <span className='header-search-add-parent'>
                    <div className='pages-serach-view'>
                      <CiSearch className='page-search-icon' />
                      <input className='pages-serach' onChange={handleSearch}
                        placeholder='Search Deduction' type='search' />
                    </div>
                    <button className='pages-addempbutton' onClick={opendeduction} >Add Deduction</button>

                  </span>
                </div>



                <div className='pages-line'></div>
              </div>
              <div className='pages-filter-options'>
                <div className='pages-filter-sub'>
                  <div className="earnings-stepper">
                    <div className="earnings-step-indicator">
                      <Link to="/addearningcomp" className='earnings-redirect-link2'>
                        <div className='earnings-step '>
                          Earnings
                        </div>
                      </Link >
                      <Link to="/adddeductioncomp" className='earnings-redirect-link'>
                        <div className='earnings-step earnings-selected'>
                          Deductions
                        </div>
                      </Link>
                    </div>


                  </div>
                </div>
              </div>
              <div className='pages-maincontent'>
                {loading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                )
                  : (
                    <table className='pages-table'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>S No</th>
                          <th>Deduction Name</th>
                          <th>Deduction Type</th>
                          <th>Calculation Type</th>
                          <th>Amount Type</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {deductioncomp && deductioncomp.length > 0 ? (
                          deductioncomp.map((deductionview, index) => (
                            <tr key={deductionview.id}>
                              <td></td>
                              <td>{index + 1}</td>
                              <td>{deductionview.component_name}</td>
                              <td>{deductionview.component}</td>
                              <td>{deductionview.calculation_type}</td>
                              <td>{deductionview.amount_type}</td>
                              <td>{deductionview.user !== null ? (
                                <span style={{ display: "flex", flexDirection: "row", gap: "20px", fontSize: "14px", alignItems: "center" }}>
                                  <CiEdit onClick={() => editDeductionButton(deductionview.id)} style={{ color: "#2a7ac7", cursor: "pointer" }} />
                                  <CiTrash onClick={() => showConfirmationModalDelete(deductionview.id)} style={{ color: "red", cursor: "pointer" }} />
                                </span>
                              ) : null} </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="no-data-table">

                              <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />



                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}

                <div style={{ float: "right" }} id="pagination_link"></div>
                <div >
                  {(!loading)&&(totaldedcount > per_page) ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totaldedcount / per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        onPageChange={currentPageNo}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Adddeduction
        show={isopendeduction}
        close={closededuction}
        sendDataToParent={handleDataFromChild}
      />
      <Editdeduction
        show={editDeduction}
        close={closeEditDeduction}
        editData={editData}
        updateData={handleUpdateData}
      />
      <DeleteAlert
        isOpen={isConfirmationModalOpen}
        message="Really, would you like to remove this Component?"
        onCancel={closeConfirmationModal}
        onConfirm={confirmDelete}
      />
    </div>

  )
}

export default Deductions;