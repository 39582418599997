import React, { useState, useEffect, useCallback } from 'react';
import './Earnings.css';
import { Link } from 'react-router-dom';
import { CiEdit, CiSearch, CiTrash } from 'react-icons/ci';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import AddEarnings from './Addearnings/AddEarnings';
import { getUserDetails } from '../../../Services/Storage';
import { deleteSalaryComponentApi, FetchEarningsSalary } from '../../../Config/api';
import DeleteAlert from '../../popup/DeleteAlert';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import pageloader from '../../../Loader/pageloading/Pageloader';
import ReactPaginate from "react-paginate";
import Editearning from './Editearnings';

const Earnings = () => {
  const user_details = getUserDetails();
  const [earingscomp, setearningcomp] = useState([])
  const [editData, setEditData] = useState([]);
  const [editEarnings, setEditEarnings] = useState(false);
  const [loading, setIsloading] = useState(false);
  const [totaldedcount, setdedcount] = useState(0)
  const per_page = 10;
  const [currentpage, setCurrentPage] = useState(1);
  const [search, setSearchword] = useState("");

  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleSearch = (event) => {
    setSearchword(event.target.value);
  }

  const fetchsalarycompearnings = useCallback(async () => {
    try {
      setIsloading(true);
      const response = await FetchEarningsSalary(`?register_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&search=${search}`);
      if (response) {
        setearningcomp(response.data.get_salary_component_earning || []);
        setdedcount(response.data.page_context.total)
      }
      // const response = await 
    } catch (error) {

    } finally {
      setIsloading(false)
    }
  }, [currentpage, search, user_details.id])
  useEffect(() => {
    fetchsalarycompearnings();
  }, [fetchsalarycompearnings]);
  const handleDataFromChild = (data) => {
    setearningcomp((prevcompArray) => [
      data,
      ...prevcompArray,
    ]);
  };

  const closeEditEarnings = () => {
    setEditEarnings(false);
  }
  // const earingscomp = earningsList.get_salary_component_earning;
  const [isopenearnings, setopenearnings] = useState(false);

  const openaddearnings = () => {
    setopenearnings(true);
  }

  const closeaddearnings = () => {
    setopenearnings(false);
  }
  const editearningsButton = (componentId) => {
    const updatedList = earingscomp.filter((component) =>
      component.id === componentId
    );
    if (updatedList) {
      setEditData(updatedList[0]);
      setEditEarnings(true);
    }
  }

  const handleUpdateData = (data) => {
    const updatedList = earingscomp.map((comp) =>
      comp.id === data.id ? data : comp
    );
    setearningcomp(updatedList);
  }
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [deleteComponentId, setDeleteComponentId] = useState(null);
  const showConfirmationModalDelete = (InvoiceId) => {
    setDeleteComponentId(InvoiceId);
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setDeleteComponentId(null);
    setConfirmationModalOpen(false);
  };

  const confirmDelete = () => {
    if (deleteComponentId) {
      deletesalaryComponent(deleteComponentId);
      closeConfirmationModal();
    }
  };
  const deletesalaryComponent = async (componentId) => {
    try {
      const response = deleteSalaryComponentApi(componentId);
      if (response) {
        const updatedList = earingscomp.filter((component) => component.id !== componentId);
        setearningcomp(updatedList);
      }
    }
    catch (error) {

    }
  }
  return (

    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>


                <div className='pages-subheader-sub-1'>
                  <p>Salary Component </p>
                  <span className='header-search-add-parent'>
                    <div className='pages-serach-view'>
                      <CiSearch className='page-search-icon' />
                      <input className='pages-serach' onChange={handleSearch} placeholder='Search Earnings' type='search' />
                    </div>
                    <button className='pages-addempbutton' onClick={openaddearnings} >Add Earnings</button>

                  </span>
                </div>



                <div className='pages-line'></div>
              </div>
              <div className='pages-filter-options'>
                <div className='pages-filter-sub'>
                  <div className="earnings-stepper">
                    <div className="earnings-step-indicator">
                      <Link to="/addearningcomp" className='earnings-redirect-link'>
                        <div className='earnings-step earnings-selected'>
                          Earnings
                        </div>
                      </Link >
                      <Link to="/adddeductioncomp" className='earnings-redirect-link2'>
                        <div className='earnings-step'>
                          Deductions
                        </div>
                      </Link>
                    </div>


                  </div>
                </div>
              </div>
              <div className='pages-maincontent'>
                {loading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                )
                  : (


                    <table className='pages-table'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>S No</th>
                          <th>Earning Name</th>
                          <th>Earning Type</th>
                          <th>Calculation Type</th>
                          <th>Amount Type</th>

                        </tr>
                      </thead>
                      <tbody>
                        {earingscomp && earingscomp.length > 0 ? (
                          earingscomp.map((earningsview, index) => (


                            <tr key={earningsview.id} >
                              <th></th>
                              <td>{index + 1}</td>
                              <td>{earningsview.component_name}</td>
                              <td>{earningsview.component}</td>

                              <td>{earningsview.calculation_type}</td>
                              <td>{earningsview.amount_type}</td>
                              <td>{earningsview.user !== null ? (
                                <span style={{ display: "flex", flexDirection: "row", gap: "20px", fontSize: "14px", alignItems: "center" }}>
                                  <CiEdit onClick={() => editearningsButton(earningsview.id)} style={{ color: "#2a7ac7", cursor: "pointer" }} />
                                  <CiTrash onClick={() => showConfirmationModalDelete(earningsview.id)} style={{ color: "red", cursor: "pointer" }} />
                                </span>
                              ) : null} </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="no-data-table">

                              <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />



                            </td>
                          </tr>
                        )
                        }
                      </tbody>
                    </table>
                  )}

                <div style={{ float: "right" }} id="pagination_link"></div>
                <div >
                  {(!loading)&&(totaldedcount > per_page) ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totaldedcount / per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        onPageChange={currentPageNo}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddEarnings
        show={isopenearnings}
        close={closeaddearnings}
        sendDataToParent={handleDataFromChild}
      />
      <Editearning
        show={editEarnings}
        close={closeEditEarnings}
        editData={editData}
        updateData={handleUpdateData}
      />
      <DeleteAlert
        isOpen={isConfirmationModalOpen}
        message="Really, would you like to remove this Component?"
        onCancel={closeConfirmationModal}
        onConfirm={confirmDelete}
      />
    </div>

  )
}

export default Earnings