import React, { useState, useEffect, useRef, useCallback } from 'react'
import { FetchEmployee, UpdateDepartmentHead } from '../../../Config/api';
import { getCompanyDetails, getUserDetails } from '../../../Services/Storage';
import { CiSearch } from 'react-icons/ci';
import { useSelector } from 'react-redux';
import { Store } from '../../../Store';
import { toast } from 'react-toastify';
import { updatedepartmenthdcomp } from '../../../Slice/departmentHeadSlice';
const Editdepartmenthead = ({ show, close, editData, updateData }) => {
  const userDetails = getUserDetails();
  const company_details = getCompanyDetails();
  const user_details = getUserDetails();
  const [searchOpen, setSearchOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const dropdownRef = useRef(null);
  const leaveTypeDropdownRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [employeename, setEmployeeName] = useState('');
  const departmentRedux = useSelector((state) => state.department);
  const [loader, setloader] = useState(false)

  const [search, setSearchword] = useState("");
  const [adddephead, setdephead] = useState({

    depart_name: '',
    depart_head: '',
    user: userDetails.id,
    company: company_details.company_id,
  })


  // const departmentData = departmentList.get_department.map(department => ({
  //     value: department.id,
  //     label: department.depart_name,
  //   }));
  //   const employeeData = emplist.get_staff_details.map(employee => ({
  //     value: employee.id,
  //     label: employee.register_details.name,
  //   }));
  const fetchEmployeeList = useCallback(async () => {

    try {
      const response = await FetchEmployee(`?register_id=${user_details.id}&search=${search}`);
      setEmployees(response.data.emp_name_id);

    } catch (error) {
    }
  }, [search, user_details.id]);
  useEffect(() => {
    fetchEmployeeList();
  }, [fetchEmployeeList]);

  const handleSearchClick = () => {
    setSearchOpen(!searchOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setSearchOpen(false);
      }

      if (
        leaveTypeDropdownRef.current &&
        !leaveTypeDropdownRef.current.contains(event.target)
      ) {
        // setleavetype(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleSearch = (event) => {
    setSearchword(event.target.value);
  };
  const handleSelectEmployee = (employee) => {
    setSearchword('');
    setSearchOpen(false);
    setdephead((prev) => ({
      ...prev,
      depart_head: employee.register_id,

    }))
    setEmployeeName(employee.name);

    // setEmployeeName(employee.name);
    // setFormData((prevData) => ({
    //   ...prevData,
    //   empid: employee.employee_id,
    //   employeename: employee.name,
    //   register: employee.register_id

    // }));
    // setEligibleLeaveDetails(employee.eligible_leave_details || []); // Ensure it's always an array

  };
  const handlesubmit = async () => {
    // const fieldData=[
    //   {
    //     name:"depart_name",
    //     type:"",
    //     err:""


    //     }

    // ]
    if (validateForm()) {
      try {
        setloader(true)
        const response = await (UpdateDepartmentHead(editData.row_id, adddephead));
        if (response) {
          updateData(response.data.updated_depart_head);
          Store.dispatch(updatedepartmenthdcomp(response.data.updated_depart_head))
          close();
          toast.success('Department Head Updated Sucessfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
          })
        }
      } catch (error) {

        const newErrors = {
          response_error: [],
        };
        for (const key in error.response.data.data) {
          if (error.response.data.data.hasOwnProperty(key)) {
            newErrors.response_error.push(`${error.response.data.data[key]}`);
          }
        }
        setErrors(newErrors);
        toast.error("Update Department Failed. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
        });
      } finally {
        setloader(false)
      }
    }
    // alert(adddephead.depart_name_id,
    //   )
  }
  const validateForm = () => {
    let formErrors = {};
    if (!adddephead.depart_name) {
      formErrors.depart_name = 'Department is required';
    }
    if (!adddephead.depart_head) {
      formErrors.depart_head = 'Employee is required';
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // Returns true if no errors
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setdephead((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {

    if (editData) {
      setdephead({
        depart_head: editData.depart_head_id,
        depart_name: editData.depart_name_id,
        user: userDetails.id,
        company: company_details.company_id,
      })
      setEmployeeName(editData.depart_head_name);

    }
  }, [editData, company_details.company_id, userDetails.id])
  if (!show) return null

  return (
    <div className='popup-section'>
      <div className='popup-container-adddepdes'>
        <span className='uploadexcel-close' onClick={close}>&times;</span>

        <div className='popup-container-sub'>
          <div className='addleave-heading'>
            <p>Add Department Head</p>

          </div>

          <div className='addleave-strightline'></div>
          <div className='popup-content-viewing-adddepdes'>

            <div className='popup-input-hdtl'>
              <label>Department<span className='registeration-mandatory'> *</span> </label>
              {/* <Select  options={departmentData} className='popup-input-hdtl2' placeholder="Select Department" /> */}

              <select className='dephdtl-select' onChange={handleInputChange} value={adddephead.depart_name} name='depart_name'>
                <option value="">Select Department</option>

                {departmentRedux.map((depart, index) => (
                  <option key={index} value={depart.id}>{depart.depart_name}</option>

                ))}
                {/* <option>zxc</option>
          <option>zxc</option> */}
              </select>
              {errors.depart_name && <span className='error-message-view'>{errors.depart_name}</span>}

            </div>
            <div className='popup-input-hdtl'>
              <label>Employee<span className='registeration-mandatory'> *</span> </label>
              {/* <Select  options={employeeData} className='popup-input-hdtl2'placeholder="Select Employee" /> */}
              {/* 
        <select className='dephdtl-select'>
        <option value="">Select Employee</option>

          {employeeData.map((emplist)=>(
            <option value={emplist.value} key={emplist.value}>{emplist.label}</option>
          ))

          }

        </select> */}
              <div ref={dropdownRef} className={`invoice-grid-div invoice-span-div invoice-sales-div ${searchOpen ? "active" : ""}`}>
                <div onClick={handleSearchClick} className='popup-input-hdt-select'>
                  <input
                    type='text'
                    placeholder='Search employee id or employee name'
                    onChange={handleInputChange}
                    // name='empid'
                    value={employeename}
                    readOnly
                  />
                </div>
                {searchOpen && (
                  <div className='invoice-sales-grid-div-dropdown'>
                    <div className='invoice-dropdown-search'>
                      <CiSearch className="las la-search" />
                      <input
                        type="text"
                        placeholder='Search Employees'
                        name="search"
                        onChange={handleSearch}
                      />
                    </div>
                    <ul>
                      {employees
                        .filter(employee =>
                          employee.name && employee.name.toLowerCase().includes(search.toLowerCase())
                        )
                        .map(employee => (
                          <li
                            key={employee.id}
                            className='sal_block'
                            onClick={() => handleSelectEmployee(employee)}
                          >
                            {employee.name}
                          </li>
                        ))}
                      {employees.length === 0 && (
                        <li className='no-data'>No data found</li>
                      )}
                    </ul>
                  </div>
                )}
                {errors.depart_head && <span className='error-message-view'>{errors.depart_head}</span>}

              </div>
              {errors.response_error && (
                <div className="registeration-error-view">{errors.response_error}</div>
              )}
            </div>


            <div className='popup-button'>
              <button onClick={handlesubmit} disabled={loader}>{loader ? <span className='spinner'></span> : "Submit"}</button>

            </div>
          </div>
        </div>

      </div>
    </div>

  )
}

export default Editdepartmenthead