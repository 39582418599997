import React, { useState, useEffect, useCallback } from 'react'
import Select from 'react-select';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import './Viewleave.css';
import Addleave from '../Addleave/Addleave';
import { CiSearch } from 'react-icons/ci';
import ReactPaginate from "react-paginate";
import { useLocation } from 'react-router-dom'

import formatDate from '../../Component/DateConvert';
import leaveStatusText from '../LeaveStatusText';
import { getUserDetails } from "../../../Services/Storage";
import {
  CompanyApi,
  DesignationApi,
} from "../../../Config/CommonApi";
import { DeleteLeavestaffApi, UpdateStatusLeave, ViewLeaveApi } from '../../../Config/api';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import getMonthYearData from '../../Component/MonthYearList';
import pageloader from '../../../Loader/pageloading/Pageloader';
// import Refreshimg from '../../../Images/Refreshimg/Refreshimg';
import { CiTrash } from 'react-icons/ci';
import { updateLeave } from '../../../Slice/leaveSlice';
import { Store } from '../../../Store';
import { toast } from 'react-toastify';
import Confirmation from '../../Component/Confirmation';
import leaveStatusalert from '../Leavestatusalertview';
import DeleteAlert from '../../popup/DeleteAlert';
const Viewleave = () => {
  const [isaddleaveopen, setaddleaveopen] = useState(false);
  const [currentpage, setCurrentPage] = useState(1);
  const [companyFilter, setCompanyFilter] = useState([]);

  const [search, setSearchword] = useState("");
  const [company, setCompany] = useState("");
  // const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  // const [employeeStatus, setEmployeeStatus] = useState("");
  const [totalLeaveCount, setTotalLeaveCount] = useState(0);
  const [companyData, setCompanyData] = useState([]);
  // const [departmentData, setDepartmentData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState([]);
  const [reloadKey, setReloadKey] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, '0')); // Default to current month, formatted as two digits
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setloading] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [statusToUpdate, setStatusToUpdate] = useState(null);
  const [designationfliter, setdesignationfilter] = useState([]);
  const [userfilter, setuserfilter] = useState({ value: "all", label: "All Status" });

  // const leaveDetailsRedux = useSelector((state) => state.leaves);
  // useEffect(() => {
  //   setLeaves(leaveDetailsRedux);
  // }, [leaveDetailsRedux])
  const [deleteComponentId, setDeleteComponentId] = useState(null);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const location = useLocation();
  const { pending = "all" } = location.state || {};
  const [leaveStatus, setleaveStatus] = useState(pending);

  const user_details = getUserDetails();
  const per_page = 10;
  // useEffect(() => {
  //   fetchleaveList();
  // },
  //   [currentpage, company, designation, selectedMonth, selectedYear, search, reloadKey]);

  useEffect(() => {
    if (pending === '0') {
      setuserfilter({ value: 0, label: "Pending" })

      
    }
  }, [pending]);
  useEffect(() => {
    const fetchCompanyData = async () => {
      const data = await CompanyApi();
      setCompanyData(data);
      setCompanyFilter(data[0]);

    };
    // const fetchDepartmentData = async () => {
    //   const data = await DepartmentApi();
    //   setDepartmentData(data);
    // };
    const fetchDesignationData = async () => {
      const data = await DesignationApi();
      setDesignationData(data);
      setdesignationfilter(data[0]);

    };
    fetchCompanyData();
    // fetchDepartmentData();
    fetchDesignationData();
  }, [reloadKey]);
  const handleChangeCompany = (selecteOption) => {
    setCompany(selecteOption.value);
    setCompanyFilter(selecteOption)
    setCurrentPage(1)


  };
  // const handleChangeDepartment = (selecteOption) => {
  //   setDepartment(selecteOption.value);
  // };
  const handleChangeDesignation = (selecteOption) => {
    setDesignation(selecteOption.value);
    setdesignationfilter(selecteOption);
    setCurrentPage(1)


  };
  // const handleChangeList = (selecteOption) => {
  //   setEmployeeStatus(selecteOption.value);
  // };
  const handleChangeYear = (selectedOption) => {
    setSelectedYear(selectedOption.value);
    setCurrentPage(1)

  };
  const handleChangeMonth = (selectedOption) => {
    setSelectedMonth(selectedOption.value);
    setCurrentPage(1)
  };
  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleCloseModal = () => {
    setShowConfirmModal(false);
  };
  const handleupdatestatus = async (status) => {
    setStatusToUpdate(status);
    setShowConfirmModal(true);
  };
  const handleConfirm = async () => {
    setShowConfirmModal(false);
    const payload = {
      status: statusToUpdate,
      ids: selectedLeave,
    }

    try {
      setloading(statusToUpdate);
      const response = await UpdateStatusLeave(JSON.stringify(payload));

      if (response) {
        Store.dispatch(updateLeave(response.data.updated_records));
        fetchleaveList();
        setSelectedLeave([]);
        toast.success('Leave Status Updated successfully!', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
        });
      }
      setloading(null);

    } catch {
      toast.error('Failed. Please try again.', {
        position: "top-center",
        autoClose: 2000,
        closeButton: false,
      });
    }
    setloading(null);
  }

  const fetchleaveList = useCallback(async () => {

    
    try {
      setIsLoading(true);
      // if(pending){
      //   const response = await ViewLeaveApi(
      //     `?register_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&search=${search}&designation=${designation}&company=${company}&year=${selectedYear}&month=${selectedMonth}&status=${0}&admin_id=${user_details.id}`
      //   );
      //   setLeaves(response.data.emp_leave_view);
      //   setTotalLeaveCount(response.data.page_context.total);
      // }else{
        const response = await ViewLeaveApi(
          `?register_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&search=${search}&designation=${designation}&company=${company}&year=${selectedYear}&month=${selectedMonth}&status=${leaveStatus}&admin_id=${user_details.id}`
        );
        setLeaves(response.data.emp_leave_view);
        setTotalLeaveCount(response.data.page_context.total);
     // }
      
      // setLeaves(response.data.emp_leave_view);
      // setTotalLeaveCount(response.data.page_context.total);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [user_details.id, currentpage, search,leaveStatus, company, designation, selectedYear, selectedMonth, per_page]);
  useEffect(() => {

    
    fetchleaveList(); // fetch data when dependencies change
  }, [fetchleaveList]);

  

  // const fetchLeaveListDefault = async () => {
  //   setCurrentPage(1);
  //   setCompany('');
  //   setSelectedYear('');
  //   setDepartment('');
  //   setDesignation('');
  //   setEmployeeStatus('');
  //   setSearchword('');
  //   setReloadKey(prevKey => prevKey + 1);
  // };

  const addleave = () => {
    setaddleaveopen(true);
  }
  const closeaddleave = () => {
    setaddleaveopen(false);
    setReloadKey(prevKey => prevKey + 1);
  }


  useEffect(() => {
    if (selectedLeave.length === leaves.length) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [selectedLeave, leaves.length]);

  const handleSearch = (event) => {
    setSearchword(event.target.value);
  }

  useEffect(() => {
    if (selectedLeave.length === leaves.length) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [selectedLeave, leaves.length]);

  const handleAllCheckboxChange = () => {
    if (isAllChecked) {
      setSelectedLeave([]);
    } else {
      setSelectedLeave(leaves.map(leave => leave.id));
    }
    setIsAllChecked(!isAllChecked);
  };
  const handleCheckboxChange = (id) => {
    setSelectedLeave(prevState =>
      prevState.includes(id) ? prevState.filter(leaveId => leaveId !== id) : [...prevState, id]
    );
  };
  const { months, years } = getMonthYearData();
  // const keys = Object.keys(months);
  const monthData = Object.keys(months).sort((a, b) => a - b).map(key => (
    {
      value: key,
      label: months[key],
    }));

  const yearData = Object.keys(years).map(key => ({
    value: years[key],
    label: years[key],
  }));
  const handleDataFromChild = (data) => {
    if (data) {
      fetchleaveList();
    }
    // setLeaves((prev) => [
    //     data,
    //     ...prev,
    // ]);
  };

  const listStatus = [
    { value: "all", label: "All Status" },
    { value: "1", label: "Approved" },
    { value: "0", label: "Pending" },
    { value: "2", label: "Rejected" },
    { value: "3", label: "Hold" },


  ];
  const handleChangeList = (selecteOption) => {
    setleaveStatus(selecteOption.value);
    setuserfilter(selecteOption);
    setCurrentPage(1);

  };
  const showConfirmationModalDelete = (delid) => {
    setDeleteComponentId(delid);
    setConfirmationModalOpen(true);

  }
  const closeConfirmationModal = () => {
    setDeleteComponentId(null);
    setConfirmationModalOpen(false);
  };
  const confirmDelete = () => {
    if (deleteComponentId) {
      deletesalaryComponent(deleteComponentId);
      closeConfirmationModal();
    }
  };
  const deletesalaryComponent = async (componentId) => {
    try {
      const response = await DeleteLeavestaffApi(componentId);
      if (response) {
        //const updatedList = leaves.filter((component) => component.id !== componentId);
        fetchleaveList();
      }
    }
    catch (error) {

    }
  }
  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>
                {selectedLeave.length === 0 ? (
                  <div className='pages-subheader-sub-1'>
                    <p>Leave</p>
                    <span className='header-search-add-parent'>
                      <div className='pages-serach-view'>
                        <CiSearch className='page-search-icon' />
                        <input className='pages-serach' onChange={handleSearch} placeholder='Search Leave ' type='search' />
                      </div>
                      <button className='pages-addempbutton' onClick={addleave}>Add Leave</button>
                    </span>
                  </div>
                ) :
                  (
                    <div className='pages-subheader-sub-2'>
                      <button className='pages-action-button pending' onClick={() => handleupdatestatus(0)} disabled={loading !== null}> {loading === 0 ? <span className='spinner-status'></span> : 'Pending'} </button>
                      <button className='pages-action-button approve' onClick={() => handleupdatestatus(1)} disabled={loading !== null}> {loading === 1 ? <span className='spinner-status'></span> : 'Approve'}</button>
                      <button className='pages-action-button hold' onClick={() => handleupdatestatus(3)} disabled={loading !== null}> {loading === 3 ? <span className='spinner-status'></span> : 'Hold'}</button>
                      <button className='pages-action-button reject' onClick={() => handleupdatestatus(2)} disabled={loading !== null}> {loading === 2 ? <span className='spinner-status'></span> : 'Reject'} </button>
                    </div>
                  )
                }
                <div className='pages-line'></div>
              </div>
              <div className='pages-filter-options'>
                <div className='pages-filter-sub'>
                  <p>Filter:</p>
                  <Select className='pages-dropdown1' options={companyData} value={companyFilter} onChange={handleChangeCompany} placeholder="Select Work Location" />
                  <Select className='pages-dropdown' options={designationData} value={designationfliter} onChange={handleChangeDesignation} placeholder="Select Designation" />
                  <Select className='pages-dropdown' options={yearData} value={yearData.find(option => option.value === selectedYear)} // Pre-select the current year
                    onChange={handleChangeYear} placeholder="Select Year" />
                  <Select className='pages-dropdown' options={monthData} onChange={handleChangeMonth} value={monthData.find(option => option.value === selectedMonth)} // Pre-select the current month
                    placeholder="Select Month" />
                     <Select
                      className='pages-dropdown-last'
                      onChange={handleChangeList}
                      options={listStatus}
                      value={userfilter}
                      placeholder="Active List"
                    />

                  {/* <button className="default-button" onClick={fetchLeaveListDefault}> Default </button> */}
                  {/* <img src={Refreshimg.refreshimg} onClick={fetchLeaveListDefault} /> */}
                </div>
              </div>
              <div className='pages-maincontent'>
                {isLoading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                ) : (
                  <table className='pages-table'>
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox" checked={isAllChecked} onChange={handleAllCheckboxChange}
                          />
                        </th>
                        <th>Emp Id</th>
                        <th>Name</th>
                        <th>From Date</th>
                        <th>To Date</th>

                        <th>No OF Days</th>
                        <th>Leave Type</th>
                        <th>Leave Reason</th>
                        <th>Status</th>
                        <th>##</th>
                      </tr>
                    </thead>
                    <tbody>
                      {leaves.length > 0 ? (
                        leaves.map((leave, index) => (
                          <tr key={leave.id}>

                            <td><input
                              type="checkbox"
                              checked={selectedLeave.includes(leave.id)}
                              onChange={() => handleCheckboxChange(leave.id)}
                            /></td>
                            <td>{leave.emp_id}</td>
                            <td>{leave.name}</td>
                            <td>{formatDate(leave.from_date)}</td>
                            <td>{formatDate(leave.to_date)}</td>
                            <td>{leave.total_days}</td>
                            <td>{leave.leave_type}</td>
                            <td>{leave.reason}</td>
                            <td style={{ color: leaveStatusText(leave.status).color }}>
                              {leaveStatusText(leave.status).text}
                            </td>
                            <td>
                              {/* {leave.status === "1"
                                ? (<></>) :

                                ( */}
                                <CiTrash onClick={() => showConfirmationModalDelete(leave.id)} style={{ color: "red", cursor: "pointer", fontSize: "14px" }} />
                               
                              {/* )
                             } */}

                            </td>                                                  </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={8} className="no-data-table">

                            <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />



                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  
                )}
                <div style={{ float: "right" }} id="pagination_link"></div>
                <div>
                  { (!loading)&&(totalLeaveCount > per_page) ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totalLeaveCount / per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        forcePage={currentpage - 1} // Adjust index (0-based)
                        onPageChange={currentPageNo}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        isOpen={showConfirmModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        message={`Are you sure you want to ${leaveStatusalert(String(statusToUpdate))} the selected leave?`}
      />
      <Addleave
        show={isaddleaveopen}
        onclose={closeaddleave}
        sendDataToParent={handleDataFromChild}

      />
      <DeleteAlert
        isOpen={isConfirmationModalOpen}
        message="Really, would you like to remove this Leave?"
        onCancel={closeConfirmationModal}
        onConfirm={confirmDelete}
      />
    </div>


  );
}

export default Viewleave