import React, { useState, useEffect, useCallback } from 'react';
import './Vieworg_profile.css'
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import { CiEdit, CiTrash } from 'react-icons/ci';
import Editorgprofile from '../Editorgprofile/Editorgprofile';
import { getUserDetails } from '../../../../Services/Storage';
import api_backend, { deleteCompany, Fetchorganization } from '../../../../Config/api';
import userdemoimg from '../../../../Images/Pagesimg/userdemo';
import Addorgprofile from '../Addorgprofile';
import pageloader from '../../../../Loader/pageloading/Pageloader';
import DeleteAlert from '../../../popup/DeleteAlert';
import Deleteconfirmation from '../../../Component/Deleteconfirmation';


const Vieworgprofile = () => {
  const user_details = getUserDetails();
  const [Organizationlist, setorganizationlist] = useState([])
  const [editData, setEditData] = useState([]);
  const [isadddepopen, setadddepopen] = useState(false);
  const [loading, setIsloading] = useState(false);
  const [showalertpopup , setshowalertpopup] = useState(false);
  const [isopenedit, setopenedit] = useState(false);
  const [orgid,setorgid]= useState(null)
  const [deletefailed , setdeletefailed] = useState(false);
  const [deletecomponentdta ,setdeletecomponentdta] = useState([])
  // const [opendeleteconf, setdeleteconf] = useState(false)

  // const openeditorg =() =>{
  //   setopenedit(true);
  // }
  const closeeditorg = () => {
    setopenedit(false);
  }

  const closeconfirmmodel =()=>{
    setshowalertpopup(false);

  }

  const Fetchcompanydetail = useCallback(async () => {
    try {
      setIsloading(true);

      const response = await Fetchorganization(`?register_id=${user_details.id}`);
      if (response) {
        // company_branch_type
        setorganizationlist(response.data.get_company);
      }
    } catch (error) {

    } finally {
      setIsloading(false);

    }
  }, [user_details.id]);

  useEffect(() => {
    Fetchcompanydetail();
  }, [Fetchcompanydetail])

  const editorgbutton = (componentid) => {
    const updatedList = Organizationlist.filter((component) =>
      component.id === componentid
    );
    if (updatedList) {
      setEditData(updatedList[0])
      setopenedit(true)

    }
  }

  const handleUpdateData = (data) => {
    
  if(data){
    Fetchcompanydetail();
  }
  }
  const openadddorg = () => {
    setadddepopen(true);
  }
  const closeaddorg = () => {
    setadddepopen(false);
  }
  const handleDataFromChild = (data) => {
    if(data){
      Fetchcompanydetail();
    }
    // setorganizationlist((prev) => [
    //   data,
    //   ...prev,
    // ]);
  };
  const openconirmationmodel=(id)=>{
    setorgid(id);
    setshowalertpopup(true);

  }
  const Deleteapi =async()=>{
    try{
const response = await deleteCompany(orgid);
if (response){
  closeconfirmmodel();

}

    }catch(error){
if(error.response && error.response.status === 422){
  console.log(error.response.data.data)
  setdeletecomponentdta(error.response.data.data);
  setdeletefailed(true);
  closeconfirmmodel();

}
    }
  }

const closedeletefailed =()=>{
  setdeletefailed(false)
}
  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>
                <div className='pages-subheader-sub-1'>

                  <p>Organization Profile</p>
                  <span className='header-search-add-parent'>

                    <button className='pages-addempbutton-leave' onClick={openadddorg}>Add Organization</button>
                  </span>
                </div>
                <div className='pages-line'></div>
              </div>

              <div className='pages2-maincontent'>
                {loading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                )
                  : (
                    <div className='vieworgpro-cards'>
                      {Organizationlist.map(Organizationlist => (
                        <div className='vieworgpro-cards-view' key={Organizationlist.id}>
                          <div className='vieworgpro-icon-edit'>

                            <CiEdit className='vieworgpro-icon-edit-sub '  onClick={() => editorgbutton(Organizationlist.id)} />
                            <CiTrash className='vieworgpro-icon-edit-sub edit-sub-vieworgpro' onClick={()=>openconirmationmodel(Organizationlist.id)}/>
                          </div>
                          <div className='vieworgpro-cards-viewsub'>

                            <div className='vieworgpro-complogo'>
                              {Organizationlist.company_logo ?
                                <img src={
                                  `${api_backend.BACKEND_API}${Organizationlist.company_logo}`

                                } alt='hi' />
                                : <img src={userdemoimg.demo_img} alt='img' />
                              }

                            </div>
                            <p className='vieworgpro-p-cardview'>
                              <p> Company Name :</p> {Organizationlist.company_name}
                              {Organizationlist.branch_type === "head"

                              ?(<>(Head)</>):(<></>)

                              }

                            

                            </p>
                            {/* <p className='vieworgpro-p-cardview'>
                          <p> Company Mobile :</p> {Organizationlist.company_phone_num}

                        </p> */}
                            <p className='vieworgpro-p-cardview'>
                              <p> Company Email :</p>{Organizationlist.company_email}

                            </p>
                            <p className='vieworgpro-p-cardview'>
                              <p> Company Address :</p>
                              {Organizationlist.address_line1},{Organizationlist.address_line2}


                            </p>
                            <p className='vieworgpro-p-cardview'>                          {Organizationlist.city},{Organizationlist.state},{Organizationlist.pincode}.
                            </p>
                          </div>

                        </div>
                      ))}





                    </div>
                  )}

              </div>
            </div>
          </div>
        </div>
      </div>
      <Editorgprofile
        editData={editData}
        show={isopenedit}
        close={closeeditorg}
        updateData={handleUpdateData}

      />
      <Addorgprofile
        close={closeaddorg}
        show={isadddepopen}
        sendDataToParent={handleDataFromChild}

      />

    <DeleteAlert
    isOpen={showalertpopup}
    message={"Really, would you like to remove this Company"}
    onCancel={closeconfirmmodel}
    onConfirm={Deleteapi}
    />
    <Deleteconfirmation
    show={deletefailed}
    close={closedeletefailed}
    data={deletecomponentdta}
    />
    </div>
  );
}

export default Vieworgprofile;
