import React, { useState, useEffect, useCallback } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import TimePicker from 'react-time-picker';
import './Addattendance.css';
import { getUserDetails } from '../../../Services/Storage';
import { addattendance, FetchEmployee } from '../../../Config/api';
import { toast } from 'react-toastify';
import DateselectComp from '../../Component/DateMutipleselect';


const Addattendance = ({ show, close, sendDataToParent }) => {
  const user_details = getUserDetails();
  const [employees, setEmployees] = useState([]);
  const [fromvalue, Setfromtime] = useState(null);
  const [tovalue, Settotime] = useState(null);
  const [errors, setErrors] = useState({});
  const [isFullDay, setIsFullDay] = useState(false); // State for checkbox
  const [loading, setloading] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);

  // const [selectedEmployees, setSelectedEmployees] = useState([]); // State for selected employees
  const [formData, setFormData] = useState({
    register_id: [],
    punch_in_time: "",
    punch_out_time: "",
    select_time: " "

  })
  const fetchEmployeeList = useCallback(async () => {
    try {
      const response = await FetchEmployee(`?register_id=${user_details.id}`);
      setEmployees(response.data.emp_name_id);
    } catch (error) {
    }
  }, [user_details.id]);

  useEffect(() => {
    fetchEmployeeList();
  }, [fetchEmployeeList]);

  const handleMultiSelectChange = (selected) => {
    const values = Array.from(selected, (option) => option.value);
    setFormData((state) => ({
      ...state,
      register_id: values,
    }));
  };
  const handlefromtimechange = (time) => {
    if (!time) return; // Avoid errors for empty or invalid time

    Setfromtime(time);
    setFormData((state) => ({
      ...state,
      punch_in_time: time + ':00',

    }));

  }
  const handletotimechange = (time) => {
    if (!time) return; // Avoid errors for empty or invalid time

    Settotime(time);
    setFormData((state) => ({
      ...state,
      punch_out_time: time + ':00',
    })

    )
  }
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((preValue) => ({
  //     ...preValue,
  //     [name]: value,
  //   }))
  // }

  const handleCheckboxChange = () => {
    setIsFullDay(!isFullDay);
    setFormData((state) => ({
      ...state,
      select_time: !isFullDay ? "fullday" : ""
    }));
  };

  const handleSubmit = async () => {
    console.log(selectedDates)
    const newErrors = {};

    if (formData.register_id.length === 0) {
      newErrors.register_id = 'Select Employee is required';
    }
    if (selectedDates.length < 0 ) {
      newErrors.date = 'Please select a date.';
    }

    if (!isFullDay && formData.punch_in_time.trim() === '') {
      newErrors.punch_in_time = 'Punch In is required';
    }
    if (!isFullDay && formData.punch_out_time.trim() === '') {
      newErrors.punch_out_time = 'Punch Out is required';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setloading(true)
        const data = {
          ...formData,
          date: selectedDates,

          ...(isFullDay && { punch_in_time: '', punch_out_time: '' }) // Remove these fields if full day is selected
        };
        const response = await addattendance(data);
        if (response) {
          sendDataToParent(response.get_attendance)
          toast.success('Attendance added successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });
          setFormData({
            register_id: [],
            date: "",
            punch_in_time: "",
            punch_out_time: "",
            select_time: " "
          });
          Setfromtime(null);
        Settotime(null);
          setIsFullDay(false)
          close();
        }

      } catch (error) {
        if (error.response) {
          const newErrors = {};
          newErrors.response_error = error.response.data.data;
          setErrors(newErrors);
        } else {
          toast.error('Failed. Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,

          });
          const newErrors = {
            response_error: [],
          };
          for (const key in error.response.data.errors) {
            if (error.response.data.errors.hasOwnProperty(key)) {
              newErrors.response_error.push(`${error.response.data.errors[key]}`);
            }
          }
          setErrors(newErrors);
          setFormData((state) => ({
            ...state,
          }));
        }
      } finally {
        setloading(false);
      }
    }

  }
  if (!show) return null;

  return (
    <div className='addatten-main'>
      <div className='addatten-main-sub'>
        <div className='addatten-container'>
          <span className='uploadexcel-close' onClick={close}>&times;</span>

          <div className='addatten-heading'>
            <p>Add Attendance</p>
          </div>
          <div className='addatten-strightline'></div>
          <div className='addatten-inputfield'>
            <div className='addatten-sub'>
              <div className='addatten-input'>
                <div className='addatten-input-sub'>
                  <div className='addatten-inputfield'>
                    <label>Employee Name</label>
                    <div>
                      <MultiSelect
                        options={employees.map(emp => ({
                          label: emp.name, // Displayed name
                          value: emp.register_id   // Unique identifier
                        }))}
                        value={formData.register_id.map(id => ({
                          label: employees.find(emp => emp.register_id === id)?.name,
                          value: id
                        }))}
                        // value={selectedEmployees}
                        onChange={handleMultiSelectChange} // Update selected employees
                        labelledBy="Select Employees"
                      />
                    </div>
                    {errors.register_id && (
                      <div className="error-message-view">{errors.register_id}</div>
                    )}
                  </div>
                  <div className='addatten-inputfield'>
                    <label>Date</label>
                    <div className='addatten-inputdate-style'>
                      <DateselectComp

                      setSelectedDates={setSelectedDates} 
                      />
                      {/* <input type='date' onChange={handleInputChange} name='date' className='addatten-input-style' /> */}
                    </div>
                    {errors.date && (
                      <div className="error-message-view">{errors.date}</div>
                    )}
                  </div>

                </div>
              </div>
              <div className='addatten-checkbox'>
                <label>
                  <input
                    type="checkbox"
                    checked={isFullDay}
                    onChange={handleCheckboxChange}
                  />
                  Full day
                </label>

              </div>
              {!isFullDay && (
                <div className='addatten-input-sub'>
                  <div className='addatten-inputfield'>
                    <label>Punch In</label>
                    <div>
                      <TimePicker
                        className="time-picker"
                        onChange={handlefromtimechange}
                        value={fromvalue}
                      />
                    </div>
                    {errors.punch_in_time && (
                      <div className="error-message-view">{errors.punch_in_time}</div>
                    )}
                  </div>
                  <div className='addatten-inputfield'>
                    <label>Punch Out</label>
                    <div>
                      <TimePicker
                        className="time-picker"
                        onChange={handletotimechange}
                        value={tovalue}
                      />
                    </div>
                    {errors.punch_out_time && (
                      <div className="error-message-view">{errors.punch_out_time}</div>
                    )}
                  </div>
                </div>
              )}
              <div className='response-message-view'>
                {errors.response_error && (
                  <div className="error-message-view">{errors.response_error}</div>
                )}
              </div>


              <div className='button-addattend'>
                <button onClick={handleSubmit} disabled={loading}>
                  {loading ? <span className="spinner"></span> : 'Submit'}

                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addattendance;
