import React, { useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import './CalendarDashboard.css';

const CalendarDashboard = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const getDaysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();
    const getFirstDayOfMonth = (month, year) => new Date(year, month, 1).getDay();

    const handlePrevMonth = () => {
        setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));
    };

    const handleDateClick = (day) => {
        setSelectedDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), day));
    };

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const today = new Date();

    const daysInMonth = getDaysInMonth(month, year);
    const firstDay = getFirstDayOfMonth(month, year);

    // Generate 42 slots to ensure a consistent grid
    const calendarDays = [];
    for (let i = 0; i < firstDay; i++) {
        calendarDays.push(null); // Empty slots before the first day
    }
    for (let day = 1; day <= daysInMonth; day++) {
        calendarDays.push(day); // Actual days of the month
    }
    while (calendarDays.length < 42) {
        calendarDays.push(null); // Empty slots after the last day
    }

    return (
        <div className="custom-calendar">
            <div className="header">
                <button onClick={handlePrevMonth}>&lt;</button>
                <div>
                    {currentDate.toLocaleString('default', { month: 'long' })} {year}
                </div>
                <button onClick={handleNextMonth}>&gt;</button>
            </div>
            <div className="days-of-week">
                {daysOfWeek.map((day) => (
                    <div key={day} className="day-label">
                        {day}
                    </div>
                ))}
            </div>
            <div className="calendar-grid">
                {calendarDays.map((day, index) => (
                    <div
                        key={index}
                        className={`day ${day ? '' : 'empty'} ${
                            selectedDate?.getDate() === day &&
                            selectedDate?.getMonth() === month &&
                            selectedDate?.getFullYear() === year
                                ? 'selected'
                                : ''
                        } ${
                            day === today.getDate() &&
                            month === today.getMonth() &&
                            year === today.getFullYear()
                                ? 'highlight'
                                : ''
                        }`}
                        onClick={day ? () => handleDateClick(day) : null}
                    >
                        {day}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CalendarDashboard;
